import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { BoardListComponent } from '../../../boards/components/board-list/board-list.component';
import { AuthService } from '../../../auth/services/auth.service';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-main',
    imports: [
        NgIf,
        AsyncPipe,
        BoardListComponent
    ],
    templateUrl: './main.component.html',
    styleUrl: './main.component.less',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainPageComponent {

	private readonly _authService = inject(AuthService);

	authResult$ = this._authService.authenticationResult$;
}
