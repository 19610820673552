import { ChangeDetectionStrategy, Component, computed, effect, inject } from '@angular/core';
import { BoardCanvasComponent } from '../../components/board-canvas/board-canvas.component';
import { BoardNavbarComponent } from "../../components/board-navbar/board-navbar.component";
import { BoardService } from '../../services/board.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, switchMap, tap } from 'rxjs';
import { BoardInteractionService } from '../../services/board-interaction.service';
import { Board } from '../../models/board.models';
import { AsyncPipe, NgIf } from '@angular/common';
import { BoardNodeTypeEnum } from '../../models/discriminators';
import { ImageCreationNodePromptsComponent } from '../../components/image-creation-node-prompts/image-creation-node-prompts.component';
import { ImageCreationNodeSettingsComponent } from '../../components/image-creation-node-settings/image-creation-node-settings.component';

@Component({
	selector: 'app-board-page',
	imports: [
		NgIf,
		AsyncPipe,
		BoardCanvasComponent,
		BoardNavbarComponent,
		ImageCreationNodePromptsComponent,
		ImageCreationNodeSettingsComponent
	],
	templateUrl: './board-page.component.html',
	styleUrl: './board-page.component.less',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoardPageComponent {

	private readonly _boardService = inject(BoardService);
	private readonly _route = inject(ActivatedRoute);
	private readonly _boardInteractionService = inject(BoardInteractionService);

	board$!: Observable<Board | null>;

	BoardNodeTypeEnum = BoardNodeTypeEnum;

	currentNode = computed(() => {
		const selected = this._boardInteractionService.currentBoardNodes();
		return selected.length === 1 ? selected[0] : null;
	});

	constructor() {
		this.board$ = this._route.paramMap.pipe(
			switchMap(v => {
				const boardIdentityCode = v.get('boardIdentityCode');
				if (boardIdentityCode) {
					return this._boardService.getBoardByCode(boardIdentityCode);
				}
				return of(null);
			}),
			tap(v => {
				this._boardInteractionService.setCurrentBoard(v);
			})
		);
	}
}
