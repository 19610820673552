<ng-container *transloco="let t; prefix: 'boards.components.boardList'">
	<div class="wrapper">
		<div class="toolbar-wrapper">
			<button mat-button (click)="createBoard()">{{ t("createButton") }}</button>
		</div>
		<div class="main">
			<div class="grid-wrapper" *ngIf="data$ | async as boards">
				<div class="board-wrapper" *ngFor="let board of boards" (click)="onBoardClick(board)">
					<div class="toolbar-wrapper">
						<app-button type="icon" iconSrc="/icons/cross-01.svg" (click)="deleteBoard(board.id); $event.stopPropagation()"></app-button>
					</div>
					<div class="board-name">{{ board.name }}</div>
				</div>
			</div>
			<div class="loader-wrapper" *ngIf="loadingList()">
				<mat-progress-spinner mode="indeterminate" [diameter]="32"></mat-progress-spinner>
			</div>
		</div>
		<div class="footer-wrapper">
			<mat-paginator [length]="totalRecords" [pageSize]="pageSize" (page)="onPage($event)"></mat-paginator>
		</div>
	</div>
</ng-container>
