<ng-container *transloco="let t; prefix: 'imageLibrary.components.imageGrid'">
	<div class="wrapper">
		<div class="cell toolbar-wrapper">
			<div class="row">
				<form class="row" [formGroup]="uploadByUrlForm" (ngSubmit)="uploadByUrl()">
					<mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic">
						<mat-label>{{ t("uploadByUrl.urlLabel") }}</mat-label>
						<input type="text" formControlName="url" matInput [errorStateMatcher]="suppressAnyErrorStateMatcher" />
					</mat-form-field>
					<button mat-icon-button [matTooltip]="t('uploadByUrl.uploadButtonTooltip')" [disabled]="uploadByUrlForm.invalid || uploadByUrlForm.disabled">
						<mat-icon class="material-icons-outlined">cloud_upload</mat-icon>
					</button>
				</form>
				<app-image-upload (upload)="onImageUpload($event)"></app-image-upload>
			</div>
			<div>
				<button mat-button (click)="close()">{{ t("closeButton") }}</button>
			</div>
		</div>
		<div class="cell main">
			<ng-scrollbar [appearance]="'compact'">
				<div class="grid-wrapper" [class.selectable]="params.isSelectable" *ngIf="data$ | async as images">
					<div class="image-wrapper" *ngFor="let image of images" (click)="onImageClick(image)">
						<img class="image" [src]="image.mediaLink" />
					</div>
				</div>
			</ng-scrollbar>
			<div class="loader-wrapper" *ngIf="loadingList()">
				<mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
			</div>
		</div>
		<div class="cell footer-wrapper">
			<mat-paginator [length]="totalRecords" [pageSize]="pageSize" (page)="onPage($event)"></mat-paginator>
		</div>
	</div>
</ng-container>
