import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, inject, Input } from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { ISelectableControl, SELECTABLE_CONTROL } from '../../interfaces/selectable-control';

type ButtonState = "normal" | "active";
type ButtonType = "normal" | "icon" | "primary" | "accent";
type ButtonSize = "s" | "m" | "l";

@Component({
	selector: 'app-button',
	imports: [
		NgClass,
		NgIf,
		IconComponent
	],
	templateUrl: './button.component.html',
	styleUrl: './button.component.less',
	providers: [
		{
			provide: SELECTABLE_CONTROL,
			useExisting: forwardRef(() => ButtonComponent),
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent implements ISelectableControl {

	private readonly _changeDetector = inject(ChangeDetectorRef);

	@Input() value: any;
	@Input() state: ButtonState = "normal";
	@Input() type: ButtonType = "normal";
	@Input() size: ButtonSize = "m";
	@Input() iconSrc?: string;

	@Input() disabled = false;

	selected = false;

	private onSelect: (value: any) => void = () => { };

	setGroupValue(value: any): void {
		this.selected = this.value === value;
		this._changeDetector.detectChanges();
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	registerOnSelect(fn: (value: any) => void): void {
		this.onSelect = fn;
	}

	select(): void {
		if (!this.disabled) {
			this.onSelect(this.value);
		}
	}

}
