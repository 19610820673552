<div class="container left">
	<div class="cell logo" [routerLink]="['/']">Fusara</div>
	<div class="cell assistant">
		<img src="/icons/assistant-01.svg" />
		<div>AI Assistant</div>
	</div>
</div>

<div class="container center">
	<div class="cell">
		<div class="button-add" [cdkMenuTriggerFor]="createNodeMenu">
			<img src="/icons/add-02.svg" />
		</div>
	</div>
</div>

<div *ngIf="currentBoard$ | async as board" class="container right">
	<div class="cell">
		<div class="board-name">{{ board.name }}</div>
	</div>
</div>

<ng-template #createNodeMenu>
	<div cdkMenu class="menu-wrapper">
		<div cdkMenuItem class="menu-item-wrapper" (click)="createNode(BoardNodeDetailedTypeEnum.TextToImage)">
			<app-icon [size]="14" src="/icons/image-01.svg"></app-icon>
			<div class="text">Prompt</div>
		</div>
		<div cdkMenuItem class="menu-item-wrapper" (click)="createNode(BoardNodeDetailedTypeEnum.Upscale)">
			<app-icon [size]="14" src="/icons/upscale-01.svg"></app-icon>
			<div class="text">Upscale</div>
		</div>
		<div cdkMenuItem class="menu-item-wrapper" (click)="createNode(BoardNodeDetailedTypeEnum.StickyNote)">
			<app-icon [size]="14" src="/icons/stick-01.svg"></app-icon>
			<div class="text">Sticky note</div>
		</div>
	</div>
</ng-template>
