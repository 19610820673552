import { DestroyRef, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class NavbarService {
	private readonly _router = inject(Router);
	private readonly _destroyRef = inject(DestroyRef);

	private readonly _isNavbarEnabled = signal<boolean>(true);
	readonly isNavbarEnabled = this._isNavbarEnabled.asReadonly();

	constructor() {
		this.listenToRouteChanges();
	}

	private enableNavbar() {
		this._isNavbarEnabled.set(true);
	}

	private disableNavbar() {
		this._isNavbarEnabled.set(false);
	}

	private listenToRouteChanges() {
		this._router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				takeUntilDestroyed(this._destroyRef)
			)
			.subscribe((event: NavigationEnd) => {
				const url = event.urlAfterRedirects;
				if (url.startsWith('/boards/')) {
					this.disableNavbar();
				} else {
					this.enableNavbar()
				}
			});
	}
}
