import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, inject, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-input',
	imports: [],
	templateUrl: './input.component.html',
	styleUrl: './input.component.less',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => InputComponent),
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputComponent implements ControlValueAccessor {

	private readonly _changeDetector = inject(ChangeDetectorRef);

	@Input() placeholder?: string;

	value?: string;
	disabled = false;
	touched = false;

	private onChange: (value: string) => void = () => { };
	private onTouched: () => void = () => { };

	writeValue(value: string | undefined): void {
		this.value = value;
		this._changeDetector.detectChanges();
	}

	registerOnChange(fn: (value: string) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	onInput(event: Event): void {
		const input = event.target as HTMLInputElement;
		this.value = input.value;
		this.onChange(this.value);
	}
}
