import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiPagedResponse, ApiResponse } from '../../../core/models/api/api.models';
import { WORKSPACE_API_URL_PREFIX } from '../../../shared/constants/api.constants';
import { Board, CreateBoardRequest, UpdateBoardRequest, UpdateBoardTransformationRequest } from '../models/board.models';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { BoardNode, CreateBoardNodeRequest, UpdateBoardNodeRequest, UpdateBoardNodeTransformationRequest } from '../models/board-node.models';

@Injectable({
	providedIn: 'root'
})
export class BoardService {

	private readonly _http = inject(HttpClient);

	constructor() { }

	///////////////
	// Boards

	getBoardByCode(code: string): Observable<Board> {
		return this._http.get<ApiResponse<Board>>(`${WORKSPACE_API_URL_PREFIX}/api/boards/${code}`).pipe(
			map(v => plainToInstance(Board, v.data))
		);
	}

	public getBoards(pageNumber: number, pageSize: number): Observable<ApiPagedResponse<Board>> {
		let params = new HttpParams()
			.set('pageNumber', pageNumber)
			.set('pageSize', pageSize);
		return this._http.get<ApiPagedResponse<Board>>(`${WORKSPACE_API_URL_PREFIX}/api/boards`, { params: params }).pipe(
			map(v => {
				return {
					...v,
					data: v.data.map(i => plainToInstance(Board, i))
				};
			})
		);
	}

	createBoard(request: CreateBoardRequest): Observable<Board> {
		const body = instanceToPlain(request);
		return this._http.post<ApiResponse<Board>>(`${WORKSPACE_API_URL_PREFIX}/api/boards`, body).pipe(
			map(v => plainToInstance(Board, v.data))
		);
	}

	updateBoard(request: UpdateBoardRequest): Observable<void> {
		const body = instanceToPlain(request);
		return this._http.put<void>(`${WORKSPACE_API_URL_PREFIX}/api/boards`, body);
	}

	updateBoardTransformation(request: UpdateBoardTransformationRequest): Observable<void> {
		const body = instanceToPlain(request);
		return this._http.patch<void>(`${WORKSPACE_API_URL_PREFIX}/api/boards/transformation`, body);
	}

	deleteBoard(id: number): Observable<void> {
		return this._http.delete<void>(`${WORKSPACE_API_URL_PREFIX}/api/boards/${id}`);
	}

	///////////////
	// Board Nodes

	getBoardNodeById(id: number): Observable<BoardNode> {
		return this._http.get<ApiResponse<BoardNode>>(`${WORKSPACE_API_URL_PREFIX}/api/board-nodes/${id}`).pipe(
			map(v => BoardNode.fromPlain(v.data))
		);
	}

	createBoardNode(request: CreateBoardNodeRequest): Observable<BoardNode> {
		const body = instanceToPlain(request);
		return this._http.post<ApiResponse<BoardNode>>(`${WORKSPACE_API_URL_PREFIX}/api/board-nodes`, body).pipe(
			map(v => BoardNode.fromPlain(v.data))
		);
	}

	updateBoardNode(request: UpdateBoardNodeRequest): Observable<void> {
		const body = instanceToPlain(request);
		return this._http.put<void>(`${WORKSPACE_API_URL_PREFIX}/api/board-nodes`, body);
	}

	updateBoardNodeTransformation(request: UpdateBoardNodeTransformationRequest): Observable<void> {
		const body = instanceToPlain(request);
		return this._http.patch<void>(`${WORKSPACE_API_URL_PREFIX}/api/board-nodes/transformation`, body);
	}

	deleteBoardNode(id: number): Observable<void> {
		return this._http.delete<void>(`${WORKSPACE_API_URL_PREFIX}/api/board-nodes/${id}`);
	}

}
