import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, inject, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-slide-toggle',
	imports: [],
	templateUrl: './slide-toggle.component.html',
	styleUrl: './slide-toggle.component.less',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => SlideToggleComponent),
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlideToggleComponent implements ControlValueAccessor {

	private readonly _changeDetector = inject(ChangeDetectorRef);

	@Input() readonly = false;

	value = false;
	disabled = false;
	touched = false;

	private onChange: (value: boolean) => void = () => { };
	private onTouched: () => void = () => { };

	writeValue(value: any): void {
		this.value = value;
		this._changeDetector.markForCheck();
	}

	registerOnChange(fn: (value: boolean) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	toggleValue(): void {
		if (this.disabled || this.readonly) {
			return;
		}
		this.value = !this.value;
		this.onChange(this.value);
		this.markAsTouched();
	}
}
