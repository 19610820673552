import { Injectable, signal } from '@angular/core';
import { Board } from '../models/board.models';
import { BoardNodeDetailedTypeEnum } from '../models/discriminators';
import { BoardNode } from '../models/board-node.models';
import { BoardNodeImage, BoardNodeTasks } from '../models/interaction.models';

@Injectable({
	providedIn: 'root'
})
export class BoardInteractionService {

	private readonly _currentBoard = signal<Board | null>(null);
	readonly currentBoard = this._currentBoard.asReadonly();

	private readonly _addingNode = signal<BoardNodeDetailedTypeEnum | null>(null);
	readonly addingNode = this._addingNode.asReadonly();

	private readonly _currentBoardNodes = signal<BoardNode[]>([]);
	readonly currentBoardNodes = this._currentBoardNodes.asReadonly();

	private readonly _boardNodeTasks = signal<BoardNodeTasks | null>(null);
	readonly boardNodeTasks = this._boardNodeTasks.asReadonly();

	private readonly _boardNodeCurrentImage = signal<BoardNodeImage | null>(null);
	readonly boardNodeCurrentImage = this._boardNodeCurrentImage.asReadonly();

	constructor() { }

	setCurrentBoard(board: Board | null): void {
		this._currentBoard.set(board);
	}

	setAddingNode(nodeType: BoardNodeDetailedTypeEnum | null): void {
		this._addingNode.set(nodeType);
	}

	setCurrentBoardNodes(nodes: BoardNode[]): void {
		this._currentBoardNodes.set(nodes);
	}

	setBoardNodeTasks(boardNodeTasks: BoardNodeTasks): void {
		this._boardNodeTasks.set(boardNodeTasks);
	}

	setBoardNodeCurrentImage(boardNodeImage: BoardNodeImage): void {
		this._boardNodeCurrentImage.set(boardNodeImage);
	}
}
