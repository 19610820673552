import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GenerateImageTaskFormComponent } from '../../components/generate-image-task-form/generate-image-task-form.component';
import { GenerateImageTaskListComponent } from '../../components/generate-image-task-list/generate-image-task-list.component';

@Component({
    selector: 'app-generations-page',
    imports: [
        GenerateImageTaskFormComponent,
        GenerateImageTaskListComponent
    ],
    templateUrl: './generations-page.component.html',
    styleUrl: './generations-page.component.less',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenerationsPageComponent {

}
