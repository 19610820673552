import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, Input, OnChanges, signal, SimpleChanges } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BoardInteractionService } from '../../services/board-interaction.service';
import { BoardNode, ImageCreationNode } from '../../models/board-node.models';
import { GeneratedImage } from '../../../imaging/models/generated-image.models';
import { ImagingService } from '../../../imaging/services/imagine.service';

@Component({
	selector: 'app-image-creation-node-tasks',
	imports: [
		NgIf,
		NgxSkeletonLoaderModule
	],
	templateUrl: './image-creation-node-tasks.component.html',
	styleUrl: './image-creation-node-tasks.component.less',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageCreationNodeTasksComponent implements OnChanges {

	private readonly _boardInteractionService = inject(BoardInteractionService);
	private readonly _imagingService = inject(ImagingService);

	@Input() node!: BoardNode;

	tasks = computed(() => {
		const md = this._boardInteractionService.boardNodeTasks();
		if (md?.node.id !== this.node.id) {
			return [];
		}
		return md.tasks;
	});

	currentImage = computed(() => {
		const selectedImage = this._boardInteractionService.boardNodeCurrentImage();
		const nodeImage = this.loaded()?.template.currentGeneratedImage;

		if (selectedImage && selectedImage.node.id === this.node.id) {
			return selectedImage.image;
		}
		return nodeImage ?? null;
	});

	loaded = signal<ImageCreationNode | null>(null);

	setCurrentImage(image: GeneratedImage) {
		this._boardInteractionService.setBoardNodeCurrentImage({
			node: this.node,
			image: image
		});

		if (this.node instanceof ImageCreationNode) {
			this._imagingService.UpdateTemplateCurrentImage({
				id: this.node.template.id,
				generatedImageId: image.id
			}).subscribe();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!changes["node"]) {
			return;
		}
		if (this.node instanceof ImageCreationNode) {
			this.loaded.set(this.node);
		}
	}
}
