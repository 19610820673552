import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { BoardService } from '../../services/board.service';
import { Board, CreateBoardRequest } from '../../models/board.models';
import { finalize, map, Observable, of, startWith, Subject, switchMap } from 'rxjs';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { AsyncPipe, NgIf, NgFor } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslocoModule } from '@jsverse/transloco';
import { Router } from '@angular/router';
import { ButtonComponent } from '../../../../core/ui-kit/components/button/button.component';
import { IconComponent } from '../../../../core/ui-kit/components/icon/icon.component';

@Component({
	selector: 'app-board-list',
	imports: [
		MatPaginatorModule,
		AsyncPipe,
		NgIf,
		NgFor,
		MatProgressSpinnerModule,
		TranslocoModule,
		MatButtonModule,
		ButtonComponent,
	],
	templateUrl: './board-list.component.html',
	styleUrl: './board-list.component.less',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoardListComponent {

	private readonly _boardService = inject(BoardService);
	private readonly _router = inject(Router);

	data$: Observable<Board[]> = of([]);
	pageSize = 8;
	totalRecords = 0;
	loadingList = signal(true);
	pageIndex: number = 0;

	refresher: Subject<void> = new Subject<void>();

	constructor() {
		this.data$ = this.refresher.pipe(
			startWith({}),
			switchMap(() => {
				this.loadingList.set(true);
				return this._boardService.getBoards(this.pageIndex + 1, this.pageSize)
					.pipe(
						finalize(() => {
							this.loadingList.set(false);
						})
					);
			}),
			map(response => {
				this.totalRecords = response.totalRecords;
				return response.data;
			})
		);
	}

	onPage(event: PageEvent) {
		this.pageIndex = event.pageIndex;
		this.refresher.next();
	}

	createBoard() {
		const request = new CreateBoardRequest();
		request.name = 'New Board';
		this._boardService.createBoard(request).subscribe(v => {
			this.navigateToBoard(v);
		});
	}

	onBoardClick(board: Board) {
		this.navigateToBoard(board);
	}

	navigateToBoard(board: Board) {
		this._router.navigate(['/boards', board.identityCode]);
	}

	deleteBoard(id: number) {
		this._boardService.deleteBoard(id).subscribe(v => {
			this.refresher.next();
		});
	}
}
