import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { BoardInteractionService } from '../../services/board-interaction.service';
import { AsyncPipe, NgIf } from '@angular/common';
import { toObservable } from '@angular/core/rxjs-interop';
import { CdkMenuModule } from '@angular/cdk/menu';
import { BoardNodeDetailedTypeEnum } from '../../models/discriminators';
import { IconComponent } from '../../../../core/ui-kit/components/icon/icon.component';

@Component({
	selector: 'app-board-navbar',
	imports: [
		RouterLink,
		NgIf,
		AsyncPipe,
		CdkMenuModule,
		IconComponent
	],
	templateUrl: './board-navbar.component.html',
	styleUrl: './board-navbar.component.less',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoardNavbarComponent {

	private readonly _boardInteractionService = inject(BoardInteractionService);

	currentBoard$ = toObservable(this._boardInteractionService.currentBoard);

	BoardNodeDetailedTypeEnum = BoardNodeDetailedTypeEnum;

	createNode(nodeType: BoardNodeDetailedTypeEnum) {
		this._boardInteractionService.setAddingNode(nodeType);
	}
}
