import { Directive, ElementRef, EventEmitter, forwardRef, HostListener, inject, Output, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
	selector: "[contenteditable][formControlName],[contenteditable][formControl],[contenteditable][ngModel]",
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => ContenteditableDirective),
		}
	],
})
export class ContenteditableDirective implements ControlValueAccessor {

	private readonly _elementRef = inject(ElementRef);
	private readonly _renderer = inject(Renderer2);

	@Output() contenteditablechange = new EventEmitter<string>();
	@Output() contenteditableblur = new EventEmitter<FocusEvent>();
	@Output() contenteditablefocus = new EventEmitter<FocusEvent>();

	private onChange: (value: string) => void = () => { };
	private onTouched: () => void = () => { };

	registerOnChange(fn: (value: string) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(disabled: boolean) {
		this._renderer.setAttribute(this._elementRef.nativeElement, "contenteditable", String(!disabled));
	}

	writeValue(value: string) {
		this._renderer.setProperty(this._elementRef.nativeElement, "innerText", value);
	}

	@HostListener("input")
	onInput() {
		const value = this._elementRef.nativeElement.innerText;
		this.onChange(value);
		this.contenteditablechange.emit(value);
	}

	/* @HostListener("keydown", ["$event"])
	onKeydown(event: KeyboardEvent) {
		if (event.key === "Enter") {
			event.preventDefault();
		}
	} */

	@HostListener("blur", ["$event"])
	onBlur(event: FocusEvent) {
		this.onTouched();
		this.contenteditableblur.emit(event);
	}

	@HostListener("focus", ["$event"])
	onFocus(event: FocusEvent) {
		this.onTouched();
		this.contenteditablefocus.emit(event);
	}
}
