import { NgIf, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, effect, ElementRef, HostBinding, HostListener, inject, Input, OnChanges, signal, SimpleChanges, untracked, viewChild, viewChildren } from '@angular/core';
import { CdkDrag, CdkDragEnd, CdkDragHandle, CdkDragMove, CdkDragStart } from '@angular/cdk/drag-drop';
import { animate, style, transition, trigger } from '@angular/animations';
import { BoardNodeComponent } from '../board-node/board-node.component';
import { ConnectionData, NodeElement, Point, Rect } from '../../models/canvas.models';
import { BoardInteractionService } from '../../services/board-interaction.service';
import { BoardNodeDetailedTypeEnum } from '../../models/discriminators';
import { Board } from '../../models/board.models';
import { BoardNode, CreateBoardNodeRequest, CreateStickyNoteBoardNodeRequest } from '../../models/board-node.models';
import { BoardService } from '../../services/board.service';
import { debounceTime, lastValueFrom, Subject } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

type CanvasState = "initial" | "connecting-nodes" | "zoom-pan" | "selecting" | "adding-node";

const minScale = 0.06;
const maxScale = 2;

@Component({
	selector: 'app-board-canvas',
	imports: [
		NgStyle,
		NgIf,
		CdkDrag,
		BoardNodeComponent,
		CdkDragHandle,
		NgxSkeletonLoaderModule,
	],
	templateUrl: './board-canvas.component.html',
	styleUrl: './board-canvas.component.less',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('fadeOut', [
			transition(':leave', [
				animate('100ms', style({ opacity: 0, transform: "scale(0)" }))
			])
		])
	]
})
export class BoardCanvasComponent implements OnChanges {

	private readonly _changeDetector = inject(ChangeDetectorRef);
	private readonly _boardInteractionService = inject(BoardInteractionService);
	private readonly _boardService = inject(BoardService);
	private readonly _destroyRef = inject(DestroyRef);

	private readonly _debouncedUpdateNodesTransformationSubject = new Subject<NodeElement[]>();
	private readonly _debouncedUpdateBoardTransformationSubject = new Subject<void>();

	@Input() board!: Board;

	canvasScale = signal(1);
	nodeRefs = viewChildren<ElementRef>('node');
	canvasRef = viewChild<ElementRef>('canvas');
	wrapperRef = viewChild<ElementRef>('wrapper');
	selectionBoxRef = viewChild<ElementRef>('selectionBox');

	connectorWidth = 2;
	connectorLineCap = "round";
	connectorColor = "#8800FF"

	nodes: NodeElement[] = [];
	selectedNodes: NodeElement[] = [];
	preselectedNodes: NodeElement[] = [];
	currentNode?: NodeElement;

	connections: ConnectionData[] = [];
	newConnection?: ConnectionData;

	canvasState = signal<CanvasState>('initial');
	isPanning = signal(false);

	panPrevPoint?: Point;
	canvasPosition: Point = { x: 0, y: 0 };

	selectionInitialPoint?: Point;
	selectionRect?: Rect;
	selectionBorderWidth = 2;

	constructor() {

		this._debouncedUpdateNodesTransformationSubject.pipe(
			debounceTime(1000),
			takeUntilDestroyed(this._destroyRef)
		).subscribe(v => this.updatNodesTransformation(v));

		this._debouncedUpdateBoardTransformationSubject.pipe(
			debounceTime(1000),
			takeUntilDestroyed(this._destroyRef)
		).subscribe(v => this.updateBoardTransformation());

		effect(() => {
			const nodeRefs = this.nodeRefs();
			nodeRefs.forEach((v, i) => {
				this.nodes[i].elementRef = v;
			});
			this.updateConnections();
		});

		effect(() => {
			this.wrapperRef()?.nativeElement.focus();
			this._changeDetector.markForCheck();
		});

		effect(() => {
			const addingNode = this._boardInteractionService.addingNode();
			if (addingNode) {
				this.canvasState.set('adding-node');
			}
			else {
				this.canvasState.set('initial');
			}
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		this.nodes = [];
		this.selectedNodes = [];
		this.preselectedNodes = [];
		this.connections = [];
		this.announceCurrentNodes();
		this.onBoardAsync();
	}

	async onBoardAsync() {
		this.canvasScale.set(this.board.currentUser.scale);
		this.canvasPosition = { x: this.board.currentUser.x, y: this.board.currentUser.y };
		const nodes = this.constructNodeElements(this.board.nodes);
		this.nodes = nodes;
		await this.loadNodesAsync(nodes);
		this._changeDetector.detectChanges();
	}

	constructNodeElements(boardNodes: BoardNode[]) {
		const nodes = boardNodes.map((v) => {
			const node: NodeElement = { id: v.id.toString(), x: v.x, y: v.y, boardNode: v, isLoaded: false };
			return node;
		});
		return nodes;
	}

	async loadNodesAsync(nodeElements: NodeElement[]) {
		return new Promise<NodeElement[]>(resolve => {
			const nodes = nodeElements.filter(v => !v.isLoaded);
			const nodesToLoad = nodes.length;
			let nodesLoaded = 0;
			for (const node of nodes) {
				this._boardService.getBoardNodeById(node.boardNode.id).subscribe(v => {
					nodesLoaded++;
					node.boardNode = v;
					node.isLoaded = true;
					if (nodesToLoad === nodesLoaded) {
						resolve(nodes);
					}
				});
			}
		});
	}

	@HostListener('document:contextmenu', ['$event'])
	onRightClick(event: MouseEvent): void {
		event.preventDefault();
	}

	@HostListener('wheel', ['$event'])
	onMouseWheel(event: WheelEvent) {
		event.preventDefault();

		const state = this.canvasState();

		if (state === 'zoom-pan' || event.ctrlKey) {
			const canvasElement = this.canvasRef()?.nativeElement;
			if (!canvasElement) {
				return;
			}
			const canvasRect = canvasElement.getBoundingClientRect();

			const scale = this.canvasScale();

			const canvasX = (event.clientX - canvasRect.left) / scale;
			const canvasY = (event.clientY - canvasRect.top) / scale;

			/* const delta = event.deltaY < 0 ? 0.02 : -0.02;
			let newScale = scale + delta;

			this.canvasScale.set(newScale);

			this.canvasPosition.x -= canvasX * delta;
			this.canvasPosition.y -= canvasY * delta; */

			const deltaStep = 0.05;
			const scaleDelta = event.deltaY < 0 ? deltaStep : -deltaStep;
			let newScale = scale * Math.exp(scaleDelta);

			newScale = Math.min(Math.max(newScale, minScale), maxScale);

			this.canvasScale.set(newScale);

			this.canvasPosition.x -= canvasX * (newScale - scale);
			this.canvasPosition.y -= canvasY * (newScale - scale);
		}
		else {
			/* this.canvasPosition.y -= event.deltaY / 2;
			this.canvasPosition.x -= event.deltaX / 2; */

			const scrollFactor = 1;
			let scaleFactor = 1;
			if (event.deltaMode === 1) {
				scaleFactor = 16;
			} else if (event.deltaMode === 2) {
				scaleFactor = 800;
			}

			const deltaX = (event.deltaX * scrollFactor * scaleFactor) / 2;
			const deltaY = (event.deltaY * scrollFactor * scaleFactor) / 2;

			this.canvasPosition.x -= deltaX;
			this.canvasPosition.y -= deltaY;
		}
		this._debouncedUpdateBoardTransformationSubject.next();
	}

	@HostListener('mousemove', ['$event'])
	onMouseMove(event: MouseEvent) {
		const state = this.canvasState();
		const scale = this.canvasScale();

		if (state === "connecting-nodes") {
			if (!this.newConnection) {
				return;
			}

			const canvasElement = this.canvasRef()?.nativeElement;
			if (!canvasElement || !this.newConnection.nodeFrom) {
				return;
			}
			const canvasRect = canvasElement.getBoundingClientRect();

			const x1 = this.newConnection.nodeFrom.x + this.newConnection.nodeFrom.elementRef!.nativeElement.offsetWidth + (this.newConnection.nodeFrom.dragPosition?.x ?? 0) / scale + 11;
			const y1 = this.newConnection.nodeFrom.y + this.newConnection.nodeFrom.elementRef!.nativeElement.offsetHeight / 2 + (this.newConnection.nodeFrom.dragPosition?.y ?? 0) / scale;

			const x2 = (event.clientX - canvasRect.left) / scale;
			const y2 = (event.clientY - canvasRect.top) / scale;

			const path = this.createBezierPath(x1, y1, x2, y2);

			this.newConnection.path = path;
		}
		else if (state === "zoom-pan" && this.isPanning()) {
			if (!this.panPrevPoint) {
				return;
			}
			const delta: Point = { x: event.clientX - this.panPrevPoint.x, y: event.clientY - this.panPrevPoint.y };
			this.panPrevPoint = { x: event.clientX, y: event.clientY };
			this.canvasPosition.x += delta.x;
			this.canvasPosition.y += delta.y;

			this._debouncedUpdateBoardTransformationSubject.next();
		}
		else if (state === "selecting") {
			if (!this.selectionRect || !this.selectionInitialPoint) {
				return;
			}

			const canvasElement = this.canvasRef()?.nativeElement;
			if (!canvasElement) {
				return;
			}
			const canvasRect = canvasElement.getBoundingClientRect();

			const x1 = this.selectionInitialPoint.x;
			const y1 = this.selectionInitialPoint.y;
			const x2 = (event.clientX - canvasRect.left) / scale;
			const y2 = (event.clientY - canvasRect.top) / scale;

			if (x1 === x2) {
				return;
			}

			this.selectionRect = { x: Math.min(x1, x2), y: Math.min(y1, y2), width: Math.abs(x2 - x1), height: Math.abs(y2 - y1) };

			// Preselect

			const selectionRect = this.selectionBoxRef()?.nativeElement?.getBoundingClientRect();
			if (!selectionRect) {
				return;
			}

			this.preselectedNodes = this.nodes.filter((v) => {
				const rect = v.elementRef?.nativeElement.getBoundingClientRect();

				const horizontallyOverlapping = rect.left < selectionRect.right && rect.right > selectionRect.left;
				const verticallyOverlapping = rect.top < selectionRect.bottom && rect.bottom > selectionRect.top;

				return horizontallyOverlapping && verticallyOverlapping;
			});
		}
	}

	@HostListener('mouseup', ['$event'])
	onMouseUp(event: MouseEvent) {
		const state = this.canvasState();
		if (state === "connecting-nodes") {
			this.newConnection = undefined;
			this.canvasState.set('initial');
		}
		else if (state === "zoom-pan") {
			this.isPanning.set(false);

			if (event.button === 2) {
				this.canvasState.set('initial');
			}
		}
		else if (state === "selecting") {

			const selectionRect = this.selectionBoxRef()?.nativeElement?.getBoundingClientRect();
			if (!selectionRect) {
				return;
			}

			this.selectedNodes = this.nodes.filter((v) => {
				const rect = v.elementRef?.nativeElement.getBoundingClientRect();

				const horizontallyOverlapping = rect.left < selectionRect.right && rect.right > selectionRect.left;
				const verticallyOverlapping = rect.top < selectionRect.bottom && rect.bottom > selectionRect.top;

				return horizontallyOverlapping && verticallyOverlapping;
			});
			this.preselectedNodes = [];

			this.canvasState.set('initial');
			this.selectionRect = undefined;

			this.announceCurrentNodes();
		}
	}

	@HostListener('mousedown', ['$event'])
	onMouseDown(event: MouseEvent) {
		const wrapperElement = this.wrapperRef()?.nativeElement;
		if (!wrapperElement) {
			return;
		}

		if (event.button === 2) {
			this.canvasState.set('zoom-pan');
		}

		const scale = this.canvasScale();

		if (this.canvasState() === 'zoom-pan') {
			this.isPanning.set(true);
			this.panPrevPoint = { x: event.clientX, y: event.clientY };
			return;
		}

		if (this.canvasState() === 'adding-node') {
			const addingNode = this._boardInteractionService.addingNode();
			this._boardInteractionService.setAddingNode(null);

			const canvasElement = this.canvasRef()?.nativeElement;
			if (!canvasElement || !addingNode) {
				return;
			}

			const canvasRect = canvasElement.getBoundingClientRect();
			const point = { x: (event.clientX - canvasRect.left) / scale, y: (event.clientY - canvasRect.top) / scale };
			this.createNode(addingNode, point);

			return;
		}

		setTimeout(() => {
			if (this.canvasState() === 'initial' && document.activeElement === wrapperElement) {
				this.canvasState.set("selecting");

				const canvasElement = this.canvasRef()?.nativeElement;
				if (!canvasElement) {
					return;
				}
				const canvasRect = canvasElement.getBoundingClientRect();
				this.selectionInitialPoint = { x: (event.clientX - canvasRect.left) / scale, y: (event.clientY - canvasRect.top) / scale };
				this.selectionRect = { x: this.selectionInitialPoint.x, y: this.selectionInitialPoint.y, width: 0, height: 0 };
			}
		}, 0);
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		const state = this.canvasState();
		if (event.code === "Space" && state === "initial") {
			this.canvasState.set("zoom-pan");
		}
		else if (event.code === "Escape" && state === "initial") {
			this.selectedNodes = [];
			this.announceCurrentNodes();
		}
		else if (event.code === "Escape" && state === "adding-node") {
			this.canvasState.set("initial");
		}
		else if ((event.code === "Delete" || event.code === "Backspace") && state === "initial") {
			this.nodes = this.nodes.filter(v => !this.selectedNodes.some(i => i === v));
			for (const node of this.nodes) {
				if (!this.nodes.some(v => v === node.nextNode)) {
					node.nextNode = undefined;
				}
			}
			this.deleteNodesAsync(this.selectedNodes);
			this.selectedNodes = [];
			this.updateConnections();
			this.announceCurrentNodes();
		}
	}

	@HostListener('keyup', ['$event'])
	onKeyUp(event: KeyboardEvent) {
		const state = this.canvasState();
		if (state === 'zoom-pan' && event.code === "Space") {
			this.canvasState.set('initial');
			this.isPanning.set(false);
		}
	}

	@HostBinding("style.--canvas-scale")
	get sizeVariable() {
		return this.canvasScale();
	}

	getNodeStyle(node: NodeElement) {
		const style = { left: `${node.x}px`, top: `${node.y}px` };
		return style;
	}

	getCanvasStyle() {
		const style = { transform: `translate(${this.canvasPosition.x}px, ${this.canvasPosition.y}px) scale(${this.canvasScale()})` };
		return style;
	}

	getWrapperStyle() {
		const scale = this.canvasScale()
		const size = scale * 200;
		const style = { backgroundSize: `${size}px`, backgroundPosition: `${this.canvasPosition.x}px ${this.canvasPosition.y}px` };
		return style;
	}

	getSelectionBoxStyle() {
		if (!this.selectionRect) {
			return;
		}
		const scale = this.canvasScale();
		const style = { left: `${this.selectionRect.x}px`, top: `${this.selectionRect.y}px`, width: `${this.selectionRect.width}px`, height: `${this.selectionRect.height}px`, borderWidth: `${this.selectionBorderWidth / scale}px` };
		return style;
	}

	onNodeDragStart(event: CdkDragStart, node: NodeElement) {
		if (this.selectedNodes.indexOf(node) === -1) {
			return;
		}
		const selectedNodes = this.selectedNodes.filter(v => v !== node);
		for (const _node of selectedNodes) {
			_node.dragStartPosition = { x: _node.x, y: _node.y };
		}
	}

	onNodeDragMove(event: CdkDragMove, node: NodeElement) {
		const scale = this.canvasScale();
		const position = event.source.getFreeDragPosition();
		const selectedNodes: NodeElement[] = [];

		node.dragPosition = position;

		if (this.selectedNodes.indexOf(node) !== -1) {
			selectedNodes.push(...this.selectedNodes.filter(v => v !== node));
			for (const _node of selectedNodes) {
				if (!_node.dragStartPosition) {
					continue;
				}
				_node.x = _node.dragStartPosition.x + position.x / scale;
				_node.y = _node.dragStartPosition.y + position.y / scale;
			}
		}

		this._debouncedUpdateNodesTransformationSubject.next([...selectedNodes, node]);

		this.updateConnections();
	}

	onNodeDragEnd(event: CdkDragEnd, node: NodeElement) {
		const position = event.source.getFreeDragPosition();
		const scale = this.canvasScale();
		node.x += position.x / scale;
		node.y += position.y / scale;
		node.dragPosition = { x: 0, y: 0 };
		event.source.setFreeDragPosition({ x: 0, y: 0 });
	}

	onNodeMouseUp(event: MouseEvent, node: NodeElement) {
		const state = this.canvasState();
		if (state === "connecting-nodes") {
			if (!this.newConnection || !this.newConnection.nodeFrom || this.newConnection.nodeFrom === node) {
				return;
			}
			this.newConnection.nodeFrom.nextNode = node;
			this.updateConnections();
			this.newConnection = undefined;
			this.canvasState.set('initial');
			event.stopPropagation();
		}
	}

	onNodeMouseDown(event: MouseEvent, node: NodeElement) {
		if (event.button === 2) {
			this.canvasState.set('zoom-pan');
		}

		if (this.canvasState() !== 'initial') {
			return;
		}

		event.stopPropagation();

		const selectedNodeIndex = this.selectedNodes.indexOf(node);
		if (event.shiftKey || event.ctrlKey) {
			if (selectedNodeIndex !== -1) {
				this.selectedNodes.splice(selectedNodeIndex, 1);
			}
			else {
				this.selectedNodes.push(node);
			}
		}
		else if (selectedNodeIndex === -1) {
			this.selectedNodes = [node];
		}

		this.announceCurrentNodes();
	}

	createConnection(node: NodeElement) {
		this.canvasState.set('connecting-nodes');
		const scale = this.canvasScale();

		const x1 = node.x + node.elementRef!.nativeElement.offsetWidth + (node.dragPosition?.x ?? 0) / scale + 11;
		const y1 = node.y + node.elementRef!.nativeElement.offsetHeight / 2 + (node.dragPosition?.y ?? 0) / scale;

		const x2 = x1;
		const y2 = y1;

		const path = this.createBezierPath(x1, y1, x2, y2);

		this.newConnection =
		{
			id: `${node.id}-new`,
			nodeFrom: node,
			path,
			connectorColor: this.connectorColor,
			connectorWidth: this.connectorWidth,
			connectorLineCap: this.connectorLineCap
		};
	}

	updateConnections() {
		this.connections = [];
		for (const node of this.nodes) {
			if (!node.nextNode) {
				continue;
			}

			const scale = this.canvasScale();

			const x1 = node.x + node.elementRef!.nativeElement.offsetWidth + (node.dragPosition?.x ?? 0) / scale + 11;
			const y1 = node.y + node.elementRef!.nativeElement.offsetHeight / 2 + (node.dragPosition?.y ?? 0) / scale;

			const x2 = node.nextNode.x + (node.nextNode.dragPosition?.x ?? 0) / scale - 11;
			const y2 = node.nextNode.y + node.nextNode.elementRef!.nativeElement.offsetHeight / 2 + (node.nextNode.dragPosition?.y ?? 0) / scale;

			const path = this.createBezierPath(x1, y1, x2, y2);

			this.connections.push(
				{
					id: `${node.id}-${node.nextNode.id}`,
					nodeFrom: node,
					nodeTo: node.nextNode,
					path,
					connectorColor: this.connectorColor,
					connectorWidth: this.connectorWidth,
					connectorLineCap: this.connectorLineCap
				}
			);
		}
	}

	createBezierPath(x1: number, y1: number, x2: number, y2: number): string {
		const dx = Math.abs(x2 - x1) / 3 + 32;
		const c1x = x1 + dx;
		const c1y = y1;
		const c2x = x2 - dx;
		const c2y = y2;
		return `M ${x1},${y1} C ${c1x},${c1y} ${c2x},${c2y} ${x2},${y2}`;
	}

	selectConnection(connection: ConnectionData) {

	}

	createNode(nodeType: BoardNodeDetailedTypeEnum, point: Point) {
		const requestPlain = { __type: nodeType, boardId: this.board.id, x: point.x, y: point.y };
		const request = CreateBoardNodeRequest.fromPlain(requestPlain);

		if (request instanceof CreateStickyNoteBoardNodeRequest) {
			request.text = "New sticky note";
		}

		this._boardService.createBoardNode(request).subscribe(async v => {
			const node = this.constructNodeElements([v])[0];
			this.nodes.push(node);
			this.selectedNodes = [node];
			await this.loadNodesAsync([node]);
			if (this.selectedNodes.length > 0) {
				this.announceCurrentNodes();
			}
			this._changeDetector.detectChanges();
		});
	}

	updatNodesTransformation(nodes: NodeElement[]) {
		for (const node of nodes) {
			this._boardService.updateBoardNodeTransformation({ id: node.boardNode.id, x: node.x, y: node.y }).subscribe();
		}
	}

	updateBoardTransformation() {
		this._boardService.updateBoardTransformation({ boardId: this.board.id, x: this.canvasPosition.x, y: this.canvasPosition.y, scale: this.canvasScale() }).subscribe();
	}

	async deleteNodesAsync(nodes: NodeElement[]) {
		for (const node of nodes) {
			await lastValueFrom(this._boardService.deleteBoardNode(node.boardNode.id));
		}
	}

	announceCurrentNodes() {
		if (this.selectedNodes.length == 1) {
			this.currentNode = this.selectedNodes[0];
		}
		else {
			this.currentNode = undefined;
		}

		if (this.selectedNodes.length == 0) {
			this._boardInteractionService.setCurrentBoardNodes([]);
			return;
		}
		const nodes = this.selectedNodes.filter(v => v.isLoaded).map(v => v.boardNode);
		if (nodes.length > 0) {
			this._boardInteractionService.setCurrentBoardNodes(nodes);
		}
	}
}
