<div class="wrapper">
	<div class="slider-wrapper">
		<div class="cell left"></div>
		<div class="cell main">
			<div class="progress"></div>
			<input type="range" [value]="value ?? 0" (input)="onInput($event)" [min]="min" [max]="max" [step]="step" />
		</div>
		<div class="cell right"></div>
	</div>
	<div class="value-wrapper" *ngIf="showValue">{{ value }}</div>
</div>
