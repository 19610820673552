export enum GenerateImageTaskStatusEnum {
	Initial = 10,
	PreprocessPromptQueued = 15,
	PreprocessingPrompt = 16,
	GenerationQueued = 20,
	Generating = 30,
	Succeeded = 100,
	Failed = 200
}

export enum PostprocessGenerateImageResultStatusEnum {
	Initial = 10,
	Queued = 20,
	Processing = 30,
	Succeeded = 100,
	Failed = 200
}

export enum GenerateImageWorkflowStatusEnum {
	Initial = 10,
	Processing = 20,
	Succeeded = 100,
	Failed = 200
}

export enum ImageFormatEnum {
	Unknown = 0,
	Png = 1,
	Jpeg = 2,
	Bmp = 3,
	Gif = 4,
	Pbm = 5,
	Tiff = 6,
	Tga = 7,
	WebP = 8
}

export enum GenerateImageTaskVisualReferenceRoleEnum {
	Image = 1,
	Character = 2,
	Style = 3
}
