import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { Language } from '../../models/i18n.models';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InternationalizationService } from '../../services/internationalization.service';

@Component({
    selector: 'app-language-select',
    imports: [
        MatMenuModule,
        MatButtonModule,
        MatIconModule
    ],
    templateUrl: './language-select.component.html',
    styleUrl: './language-select.component.less',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageSelectComponent {

	private readonly _i18nService = inject(InternationalizationService);

	currentLanguage = this._i18nService.currentLanguage;
	availableLanguages = this._i18nService.availableLanguages;

	dropdownIcon = signal("arrow_drop_down");

	setLanguage(lang: Language) {
		this._i18nService.setCurrentLanguage(lang);
	}

	onMenuStateChange(open: boolean) {
		if (open) {
			this.dropdownIcon.set("arrow_drop_up");
		}
		else {
			this.dropdownIcon.set("arrow_drop_down");
		}
	}
}
