import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { UserProfile } from '../../../../core/models/users/user-profile';
import { Observable } from 'rxjs';
import { TranslocoModule } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
    selector: 'app-profile',
    imports: [
        MatCardModule,
        MatButtonModule,
        AsyncPipe,
        NgIf,
        TranslocoModule,
        MatIconModule
    ],
    templateUrl: './profile.component.html',
    styleUrl: './profile.component.less',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfilePageComponent {

	private readonly _userService = inject(UserService);
	private readonly _authService = inject(AuthService);

	profile$: Observable<UserProfile>;

	constructor() {
		this.profile$ = this._userService.getProfile();
	}

	logout() {
		this._authService.logout().subscribe();
	}
}
