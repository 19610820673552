import { Expose, Type } from "class-transformer";
import { ImagingProviderEnum } from "./discriminators";
import { PostprocessGenerateImageResultStatusEnum } from "./enumerations";
import { GenerateImageTask, GenerateMidjourneyImageTask, GenerateSDImageTask, UpscaleImageTask } from "./generate-image-task.models";
import { GeneratedImage } from "./generated-image.models";

export abstract class GenerateImageResult {
	@Expose() __type!: ImagingProviderEnum;

	@Expose() id!: number;
	@Expose() externalId!: string;
	@Expose() rawJsonResult!: string;
	@Expose() postprocessStatusId!: PostprocessGenerateImageResultStatusEnum;
	@Expose() @Type(() => GenerateImageTask, {
		discriminator: {
			property: '__type',
			subTypes: [
				{ value: GenerateMidjourneyImageTask, name: ImagingProviderEnum.Midjourney as unknown as string },
				{ value: GenerateSDImageTask, name: ImagingProviderEnum.SD as unknown as string },
				{ value: UpscaleImageTask, name: ImagingProviderEnum.Upscale as unknown as string }
			],
		},
		keepDiscriminatorProperty: true,
	})
	task?: GenerateImageTask;
	@Expose() @Type(() => GeneratedImage) generatedImages?: GeneratedImage[];
}

export class GenerateMidjourneyImageResult extends GenerateImageResult {
	@Expose() hash!: string;
	@Expose() url!: string;
	@Expose() proxyUrl!: string;
	@Expose() width!: number;
	@Expose() height!: number;
	@Expose() finalPrompt!: string;

	constructor() {
		super();
		this.__type = ImagingProviderEnum.Midjourney;
	}
}

export class GenerateSDImageResult extends GenerateImageResult {

	constructor() {
		super();
		this.__type = ImagingProviderEnum.SD;
	}
}

export class UpscaleImageResult extends GenerateImageResult {

	constructor() {
		super();
		this.__type = ImagingProviderEnum.Upscale;
	}
}
