<div
	#wrapper
	class="wrapper"
	tabindex="0"
	[ngStyle]="getWrapperStyle()"
	[class.selecting]="canvasState() === 'selecting'"
	[class.zoom-pan]="canvasState() === 'zoom-pan'"
	[class.is-panning]="isPanning()"
	[class.adding-node]="canvasState() === 'adding-node'"
>
	<div #canvas class="canvas" [ngStyle]="getCanvasStyle()">
		@for(node of nodes; track node.id) {
		<div
			#node
			tabindex="0"
			@fadeOut
			cdkDrag
			[cdkDragDisabled]="canvasState() !== 'initial'"
			[cdkDragScale]="canvasScale()"
			class="node"
			[ngStyle]="getNodeStyle(node)"
			(cdkDragStarted)="onNodeDragStart($event, node)"
			(cdkDragMoved)="onNodeDragMove($event, node)"
			(cdkDragEnded)="onNodeDragEnd($event, node)"
			(mouseup)="onNodeMouseUp($event, node)"
			(mousedown)="onNodeMouseDown($event, node)"
			[class.state-connecting-nodes]="canvasState() === 'connecting-nodes' && newConnection?.nodeFrom != node"
			[class.selected]="selectedNodes.indexOf(node) !== -1"
			[class.preselected]="preselectedNodes.indexOf(node) !== -1"
			[class.current]="currentNode === node"
		>
			<app-board-node *ngIf="node.isLoaded; else nodeSkeleton" cdkDragHandle [node]="node.boardNode" [selected]="currentNode === node"> </app-board-node>

			<!-- <div class="connection-point input" tabindex="0"></div>
			<div class="connection-point output" tabindex="0" (mousedown)="$event.preventDefault(); createConnection(node); $event.stopPropagation()"></div> -->
		</div>
		}

		<svg class="connectors-svg">
			<defs>
				<marker id="arrow" markerWidth="8" markerHeight="8" refX="4" refY="4" orient="auto" markerUnits="strokeWidth">
					<path d="M1,1 L4,4 L1,7" fill="none" [attr.stroke]="connectorColor" stroke-width="1" />
				</marker>
				<!-- <marker id="arrow" markerWidth="10" markerHeight="10" refX="5" refY="5" orient="auto" markerUnits="strokeWidth">
					<path d="M2,3 L5,5 L2,7" fill="none" [attr.stroke]="connectorColor" stroke-width="1" />
				</marker> -->
			</defs>

			@for(connection of connections; track connection.id) {
			<path
				[attr.d]="connection.path"
				[attr.stroke]="connection.connectorColor"
				[attr.stroke-width]="connection.connectorWidth / canvasScale()"
				fill="none"
				[attr.stroke-linecap]="connection.connectorLineCap"
				marker-end="url(#arrow)"
				(click)="selectConnection(connection)"
			></path>
			} @if(newConnection) {
			<path
				[attr.d]="newConnection.path"
				[attr.stroke]="newConnection.connectorColor"
				[attr.stroke-width]="newConnection.connectorWidth / canvasScale()"
				fill="none"
				[attr.stroke-linecap]="newConnection.connectorLineCap"
				marker-end="url(#arrow)"
			></path>
			}
		</svg>

		@if(canvasState() === 'selecting') {
		<div #selectionBox @fadeOut class="selection-box" [ngStyle]="getSelectionBoxStyle()"></div>
		}
	</div>
</div>

<ng-template #nodeSkeleton>
	<ngx-skeleton-loader
		class="skeleton"
		appearance="line"
		[theme]="{ display: 'block', outline: 'none', width: '376px', height: '376px', margin: '12px', border: '1px solid #c0c0c0', cursor: 'inherit', 'border-radius': '10px' }"
	/>
</ng-template>
