<div class="navbar-wrapper">
	<app-board-navbar></app-board-navbar>
</div>
<ng-container *ngIf="board$ | async as board">
	<app-board-canvas [board]="board"></app-board-canvas>

	<ng-container *ngIf="currentNode() as node">
		<div class="settings-center-wrapper" *ngIf="node.__type === BoardNodeTypeEnum.ImageCreation">
			<app-image-creation-node-prompts [node]="node"></app-image-creation-node-prompts>
		</div>
		<div class="settings-right-wrapper" *ngIf="node.__type === BoardNodeTypeEnum.ImageCreation">
			<app-image-creation-node-settings [node]="node"></app-image-creation-node-settings>
		</div>
	</ng-container>
</ng-container>
