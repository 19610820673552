import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LanguageSelectComponent } from "../../../i18n/components/language-select/language-select.component";
import { DropdownComponent } from "../dropdown/dropdown.component";
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-navbar',
    imports: [
        MatToolbarModule,
        LanguageSelectComponent,
        DropdownComponent,
        RouterLink
    ],
    templateUrl: './navbar.component.html',
    styleUrl: './navbar.component.less',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent {

}
