<div class="wrapper" [class.selected]="selected">
	<div class="image-container">
		<ng-container>
			<img class="image" *ngIf="image() !== null; else dummy" [src]="image()?.mediaLink" [class.loading]="loading()" />
		</ng-container>
		<ng-container *ngIf="loading()">
			<ng-container *ngTemplateOutlet="preload"></ng-container>
		</ng-container>
	</div>
</div>

<ng-template #dummy>
	<div class="dummy"></div>
</ng-template>

<ng-template #preload>
	<ngx-skeleton-loader
		appearance="line"
		[theme]="{ position: 'absolute', display: 'block', left: 0, top: 0, outline: 'none', width: '100%', height: '100%', 'border-radius': '10px' }"
	></ngx-skeleton-loader>
</ng-template>
