import { inject, Injectable } from '@angular/core';
import { map, Observable, switchMap, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IConfigurationService } from '../../../core/services/configuration/configuration.service';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { GlobalSignalingService } from '../../../shared/services/signaling/global-signaling.service';
import { AuthenticationResult } from '../models/auth.models';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	private readonly _http = inject(HttpClient);
	private readonly _config = inject(IConfigurationService);
	private readonly _router = inject(Router);
	private readonly _oidcService = inject(OidcSecurityService);
	private readonly _signalingService = inject(GlobalSignalingService);

	private readonly _httpOptions = {
		withCredentials: true
	};

	authenticationResult$: Observable<AuthenticationResult> = this._oidcService.isAuthenticated$.pipe(
		map(v => ({ isAuthenticated: v.isAuthenticated }))
	);

	constructor() { }

	login() {
		this._oidcService.authorize();
	}

	logout(): Observable<void> {
		return this._config.getConfiguration().pipe(
			switchMap(v => {
				return this._http.get<void>(v.endpoints.authRoot + "/api/users/logout", this._httpOptions)
			}),
			tap(async () => {
				this._oidcService.logoffLocal();
				await this._signalingService.disconnect();
				this._router.navigate(['/']);
			})
		);
	}
}
