import { animate, style, transition, trigger } from '@angular/animations';
import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, inject, Input } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ContenteditableDirective } from '../../../../shared/directives/contenteditable.directive';
import { NgScrollbarModule } from 'ngx-scrollbar';

@Component({
	selector: 'app-textarea-ng',
	imports: [
		NgIf,
		ContenteditableDirective,
		FormsModule,
		NgScrollbarModule,
	],
	templateUrl: './textarea-ng.component.html',
	styleUrl: './textarea-ng.component.less',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => TextareaNgComponent),
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('fade', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('100ms', style({ opacity: 1 }))
			]),
			transition(':leave', [
				animate('100ms', style({ opacity: 0 }))
			]),
		])
	]
})
export class TextareaNgComponent implements ControlValueAccessor {

	private readonly _changeDetector = inject(ChangeDetectorRef);

	@Input() placeholder?: string;
	@Input() label?: string;

	value?: string;
	disabled = false;
	touched = false;

	isLabelVisible = true;

	private onChange: (value: string) => void = () => { };
	private onTouched: () => void = () => { };

	writeValue(value: string | undefined): void {
		this.value = value;
		this._changeDetector.detectChanges();
	}

	registerOnChange(fn: (value: string) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	onInput(value: string): void {
		this.value = value;
		this.onChange(this.value);
	}

	onScroll(event: Event): void {
		const textarea = event.target as HTMLTextAreaElement;
		const threshold = 10;
		this.isLabelVisible = textarea.scrollTop < threshold;
		this._changeDetector.detectChanges();
	}
}
