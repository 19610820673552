import { Expose, plainToInstance, Type } from "class-transformer";
import { ImagingProviderEnum } from "./discriminators";
import { GenerateImageTask, GenerateMidjourneyImageTask, GenerateSDImageTask, UpscaleImageTask } from "./generate-image-task.models";
import { UploadedImage } from "../../image-library/models/uploaded-image.models";
import { GeneratedImage } from "./generated-image.models";

export abstract class GenerateImageTaskTemplate {
	@Expose() __type!: ImagingProviderEnum;

	@Expose() id!: number;
	@Expose() prompt!: string;
	@Expose() negativePrompt?: string;
	@Expose() isEnabled!: boolean;
	@Expose() @Type(() => GenerateImageTask, {
		discriminator: {
			property: '__type',
			subTypes: [
				{ value: GenerateMidjourneyImageTask, name: ImagingProviderEnum.Midjourney as unknown as string },
				{ value: GenerateSDImageTask, name: ImagingProviderEnum.SD as unknown as string },
				{ value: UpscaleImageTask, name: ImagingProviderEnum.Upscale as unknown as string }
			],
		},
		keepDiscriminatorProperty: true,
	})
	tasks: GenerateImageTask[] = [];

	static fromPlain(object: GenerateImageTaskTemplate): GenerateImageTaskTemplate {
		if (object.__type === ImagingProviderEnum.Midjourney) {
			return plainToInstance(GenerateMidjourneyImageTaskTemplate, object, { excludeExtraneousValues: true });
		}
		else if (object.__type === ImagingProviderEnum.SD) {
			return plainToInstance(GenerateSDImageTaskTemplate, object, { excludeExtraneousValues: true });
		}
		else if (object.__type === ImagingProviderEnum.Upscale) {
			return plainToInstance(UpscaleImageTaskTemplate, object, { excludeExtraneousValues: true });
		}
		throw new Error("Unknown object type");
	}
}

export class GenerateMidjourneyImageTaskTemplate extends GenerateImageTaskTemplate {
	@Expose() aspectRatio?: string;
	@Expose() chaos?: number;
	@Expose() quality?: string;
	@Expose() seed?: string;
	@Expose() characterReference?: string;
	@Expose() characterWeight?: number;
	@Expose() imagePrompt?: string;
	@Expose() imageWeight?: number;
	@Expose() style?: string;
	@Expose() styleReference?: string;
	@Expose() styleWeight?: number;
	@Expose() stylize?: number;
	@Expose() tile?: boolean;
	@Expose() weird?: number;
	@Expose() version?: string;

	constructor() {
		super();
		this.__type = ImagingProviderEnum.Midjourney;
	}
}

export class GenerateSDImageTaskTemplate extends GenerateImageTaskTemplate {
	@Expose() numberOfImages?: number;
	@Expose() width?: number;
	@Expose() height?: number;
	@Expose() numberOfInferenceSteps?: number;
	@Expose() guidanceScale?: number;

	constructor() {
		super();
		this.__type = ImagingProviderEnum.SD;
	}
}

export class UpscaleImageTaskTemplate extends GenerateImageTaskTemplate {
	@Expose() scaleFactor!: number;
	@Expose() @Type(() => UploadedImage) uploadedImageToUpscale!: UploadedImage;
	@Expose() @Type(() => GeneratedImage) generatedImageToUpscale!: GeneratedImage;

	constructor() {
		super();
		this.__type = ImagingProviderEnum.Upscale;
	}
}

///// Requests

export abstract class UpdateGenerateImageTaskTemplateRequest {
	@Expose() __type!: ImagingProviderEnum;

	@Expose() id!: number;
	@Expose() prompt?: string;
	@Expose() negativePrompt?: string;
	@Expose() isEnabled!: boolean;

	static fromPlain(object: UpdateGenerateImageTaskTemplateRequest): UpdateGenerateImageTaskTemplateRequest {
		if (object.__type === ImagingProviderEnum.Midjourney) {
			return plainToInstance(UpdateGenerateMidjourneyImageTaskTemplateRequest, object, { excludeExtraneousValues: true });
		}
		else if (object.__type === ImagingProviderEnum.SD) {
			return plainToInstance(UpdateGenerateSDImageTaskTemplateRequest, object, { excludeExtraneousValues: true });
		}
		else if (object.__type === ImagingProviderEnum.Upscale) {
			return plainToInstance(UpdateUpscaleImageTaskTemplateRequest, object, { excludeExtraneousValues: true });
		}
		throw new Error("Unknown object type");
	}
}

export class UpdateGenerateMidjourneyImageTaskTemplateRequest extends UpdateGenerateImageTaskTemplateRequest {
	@Expose() aspectRatio?: string;
	@Expose() chaos?: number;
	@Expose() quality?: string;
	@Expose() seed?: string;
	@Expose() characterReference?: string;
	@Expose() characterWeight?: number;
	@Expose() imagePrompt?: string;
	@Expose() imageWeight?: number;
	@Expose() style?: string;
	@Expose() styleReference?: string;
	@Expose() styleWeight?: number;
	@Expose() stylize?: number;
	@Expose() tile?: boolean;
	@Expose() weird?: number;
	@Expose() version?: string;

	constructor() {
		super();
		this.__type = ImagingProviderEnum.Midjourney;
	}
}

export class UpdateGenerateSDImageTaskTemplateRequest extends UpdateGenerateImageTaskTemplateRequest {
	@Expose() numberOfImages?: number;
	@Expose() width?: number;
	@Expose() height?: number;
	@Expose() numberOfInferenceSteps?: number;
	@Expose() guidanceScale?: number;

	constructor() {
		super();
		this.__type = ImagingProviderEnum.SD;
	}
}

export class UpdateUpscaleImageTaskTemplateRequest extends UpdateGenerateImageTaskTemplateRequest {
	@Expose() scaleFactor?: number;
	@Expose() uploadedImageToUpscaleId?: number;
	@Expose() generatedImageToUpscaleId?: number;

	constructor() {
		super();
		this.__type = ImagingProviderEnum.Upscale;
	}
}

///////////////////////////////

export class UpdateGenerateImageTaskTemplatePromptRequest {
	@Expose() id!: number;
	@Expose() prompt?: string;
	@Expose() negativePrompt?: string;
}
