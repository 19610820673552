import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { AuthService } from '../../../auth/services/auth.service';
import { TranslocoModule } from '@jsverse/transloco';



@Component({
    selector: 'app-dropdown',
    imports: [
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        NgIf,
        AsyncPipe,
        RouterLink,
        TranslocoModule
    ],
    templateUrl: './dropdown.component.html',
    styleUrl: './dropdown.component.less',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownComponent {

	private readonly _authService = inject(AuthService);

	authResult$ = this._authService.authenticationResult$;

	constructor() { }

	login() {
		this._authService.login();
	}

	logout() {
		this._authService.logout().subscribe();
	}
}
