import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TooltipDirective } from '../../directives/tooltip.directive';

@Component({
    selector: 'app-hint-trigger',
    imports: [
        TooltipDirective
    ],
    templateUrl: './hint-trigger.component.html',
    styleUrl: './hint-trigger.component.less',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HintTriggerComponent {
	@Input({ required: true }) description!: string;
}
