import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, HostBinding, inject, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-range-slider',
	imports: [
		NgIf
	],
	templateUrl: './range-slider.component.html',
	styleUrl: './range-slider.component.less',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => RangeSliderComponent),
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RangeSliderComponent {

	private readonly _changeDetector = inject(ChangeDetectorRef);

	@Input() min = 0;
	@Input() max = 100;
	@Input() step = 1;
	@Input() showValue = false;

	value?: number;
	disabled = false;
	touched = false;

	private onChange: (value: any) => void = () => { };
	private onTouched: () => void = () => { };

	writeValue(value: any): void {
		this.value = value;
		this._changeDetector.markForCheck();
	}

	registerOnChange(fn: (value: any) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	onInput(event: Event): void {
		if (this.disabled) {
			return;
		}
		const input = event.target as HTMLInputElement;
		this.value = Number(input.value);
		this.onChange(this.value);
		this.markAsTouched();
	}

	@HostBinding("style.--range-fraction")
	get sizeVariable() {
		const abs = this.max - this.min;
		if (!this.value || abs === 0) {
			return '0';
		}
		return this.value / abs;
	}
}

