<div class="wrapper">
	@for(task of tasks(); track task.id) {
	<ng-container *ngIf="task.result?.generatedImages">
		@for(image of task.result?.generatedImages; track image.id) {
		<div class="image-wrapper" (click)="setCurrentImage(image)" [class.current]="currentImage()?.id === image.id">
			<img class="image" [src]="image.mediaLink" />
		</div>
		}
	</ng-container>
	<ng-container *ngIf="!task.result">
		<ngx-skeleton-loader appearance="line" [theme]="{ display: 'block', outline: 'none', width: '100%', height: '100%', 'border-radius': '8px' }"></ngx-skeleton-loader>
	</ng-container>
	}
</div>
