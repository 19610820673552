export enum ImagingProviderEnum {
	Midjourney = 1,
	SD = 2,
	Upscale = 3
}

export enum ImageCreationTemplateTypeEnum {
	TextToImage = 1,
	Upscale = 2,
	Inpaint = 3,
	Outpaint = 4,
	Vary = 5
}
