<div class="wrapper">
	<label class="container" [class.disabled]="disabled" [class.readonly]="readonly" [class.checked]="value">
		<input type="checkbox" [checked]="value" (change)="toggleValue()" />
		<div class="checkmark-wrapper">
			<div class="checkmark-background"></div>
			<div class="checkmark">
				<img class="icon" src="/icons/slide-toggle-01.svg" />
			</div>
		</div>
	</label>
</div>
