import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, Input, OnChanges, OnInit, signal, SimpleChanges } from '@angular/core';
import { BoardNode, ImageCreationNode } from '../../models/board-node.models';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TextareaNgComponent } from '../../../../core/ui-kit/components/textarea-ng/textarea-ng.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SlideToggleComponent } from '../../../../core/ui-kit/components/slide-toggle/slide-toggle.component';
import { ButtonComponent } from '../../../../core/ui-kit/components/button/button.component';
import { NgIf } from '@angular/common';
import { ImagingService } from '../../../imaging/services/imagine.service';
import { plainToInstance } from 'class-transformer';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs';
import { ProcessImageCreationTemplateRequest, UpdateImageCreationTemplatePromptRequest } from '../../../imaging/models/image-creation-template.models';
import { ImageCreationNodeTasksComponent } from "../image-creation-node-tasks/image-creation-node-tasks.component";

@Component({
	selector: 'app-image-creation-node-prompts',
	imports: [
		FormsModule,
		ReactiveFormsModule,
		TextareaNgComponent,
		SlideToggleComponent,
		ButtonComponent,
		NgIf,
		ImageCreationNodeTasksComponent
	],
	templateUrl: './image-creation-node-prompts.component.html',
	styleUrl: './image-creation-node-prompts.component.less',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageCreationNodePromptsComponent implements OnChanges, OnInit {

	private readonly _changeDetector = inject(ChangeDetectorRef);
	private readonly _destroyRef = inject(DestroyRef);
	private readonly _imagingService = inject(ImagingService);

	@Input() node!: BoardNode;

	loading = signal(false);

	readonly formTemplate = new FormGroup({
		prompt: new FormControl<string | undefined>(undefined, [Validators.required]),
		negativePrompt: new FormControl<string | null>(null),
		customizePrompt: new FormControl<boolean>(false),
	});

	readonly formTask = new FormGroup({
		prompt: new FormControl<string | null>(null, [Validators.required]),
		negativePrompt: new FormControl<string | null>(null),
	});

	ngOnInit(): void {
		this.formTemplate.valueChanges.pipe(
			takeUntilDestroyed(this._destroyRef),
			debounceTime(300),
			distinctUntilChanged()
		).subscribe((v) => {
			if (this.node instanceof ImageCreationNode) {
				const request = plainToInstance(UpdateImageCreationTemplatePromptRequest, v);
				request.id = this.node.template.id;
				this._imagingService.UpdateTemplatePrompt(request).subscribe();
				Object.assign(this.node.template, { ...v });
			}
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.formTemplate.reset();
		if (this.node instanceof ImageCreationNode) {
			const patchValue = {
				prompt: this.node.template.prompt ?? "",
				negativePrompt: this.node.template.negativePrompt ?? "",
			};
			this.formTemplate.patchValue(patchValue);
		}
	}

	processTemplate() {
		if (this.node instanceof ImageCreationNode) {
			if (this.formTemplate.invalid) {
				return;
			}

			this.loading.set(true);

			const request = <ProcessImageCreationTemplateRequest>{
				id: this.node.template.id
			};

			this._imagingService.ProcessTemplate(request).pipe(
				finalize(() => this.loading.set(false))
			).subscribe();
		}
	}
}

