<ng-scrollbar appearance="compact" visibility="hover">
	<div class="wrapper">
		<form [formGroup]="formSD" class="provider-wrapper">
			<div class="header-wrapper" (click)="formSD.controls.isEnabled.setValue(!formSD.controls.isEnabled.value)">
				<div class="cell">
					<app-icon src="/icons/stable-diffusion-01.svg" [size]="20"></app-icon>
					<div>Stable Diffusion</div>
				</div>
				<app-checkbox formControlName="isEnabled"></app-checkbox>
			</div>
			<div class="settings-container" [class.expanded]="formSD.controls.isEnabled.value">
				<div class="settings-wrapper">
					<div class="block">
						<div class="row caption">Inference steps</div>
						<div class="row">
							<div class="cell"><app-input formControlName="numberOfInferenceSteps"></app-input></div>
						</div>
					</div>
					<div class="block">
						<div class="row caption">Guidance scale</div>
						<div class="row">
							<div class="cell"><app-input formControlName="guidanceScale"></app-input></div>
						</div>
					</div>
					<div class="block">
						<div class="row caption">Images</div>
						<app-control-group formControlName="numberOfImages" class="row">
							<app-button class="cell" size="s" [value]="1">1</app-button>
							<app-button class="cell" size="s" [value]="2">2</app-button>
							<app-button class="cell" size="s" [value]="3">3</app-button>
							<app-button class="cell" size="s" [value]="4">4</app-button>
						</app-control-group>
					</div>
					<div class="block">
						<div class="row caption">Aspect ratio</div>
						<app-control-group class="row" [(ngModel)]="aspectRatio" [ngModelOptions]="{ standalone: true }" (ngModelChange)="recalculateDimensions()">
							<app-button class="cell" size="s" value="9:16">9:16</app-button>
							<app-button class="cell" size="s" value="3:4">3:4</app-button>
							<app-button class="cell" size="s" value="1:1">1:1</app-button>
							<app-button class="cell" size="s" value="4:3">4:3</app-button>
							<app-button class="cell" size="s" value="16:9">16:9</app-button>
						</app-control-group>
					</div>
					<div class="block">
						<div class="row caption">Size</div>
						<app-control-group class="row" [(ngModel)]="imageSize" [ngModelOptions]="{ standalone: true }" (ngModelChange)="recalculateDimensions()">
							<app-button class="cell" size="s" value="small">{{ findDimensionBySizeAndAspectRatio("small", aspectRatio)?.label }}</app-button>
							<app-button class="cell" size="s" value="medium">{{ findDimensionBySizeAndAspectRatio("medium", aspectRatio)?.label }}</app-button>
							<app-button class="cell" size="s" value="large">{{ findDimensionBySizeAndAspectRatio("large", aspectRatio)?.label }}</app-button>
						</app-control-group>
					</div>
				</div>
			</div>
		</form>
		<form [formGroup]="formMidjourney">
			<div class="header-wrapper" (click)="formMidjourney.controls.isEnabled.setValue(!formMidjourney.controls.isEnabled.value)">
				<div class="cell">
					<app-icon src="/icons/midjourney-01.svg" [size]="20"></app-icon>
					<div>M</div>
				</div>
				<app-checkbox formControlName="isEnabled"></app-checkbox>
			</div>
			<div class="settings-container" [class.expanded]="formMidjourney.controls.isEnabled.value">
				<div class="settings-wrapper">
					<div class="block">
						<div class="row caption">Stylization</div>
						<div class="row">
							<div class="cell"><app-range-slider formControlName="stylize" [min]="0" [max]="1000" [step]="100" [showValue]="true"></app-range-slider></div>
						</div>
					</div>
					<div class="block">
						<div class="row caption">Weirdness</div>
						<div class="row">
							<div class="cell"><app-range-slider formControlName="weird" [min]="0" [max]="3000" [step]="100" [showValue]="true"></app-range-slider></div>
						</div>
					</div>
					<div class="block">
						<div class="row caption">Variety</div>
						<div class="row">
							<div class="cell"><app-range-slider formControlName="chaos" [min]="0" [max]="100" [step]="10" [showValue]="true"></app-range-slider></div>
						</div>
					</div>
					<div class="block">
						<div class="row caption">Quality</div>
						<app-control-group formControlName="quality" class="row">
							<app-button class="cell" size="s" value=".25">.25</app-button>
							<app-button class="cell" size="s" value=".5">.5</app-button>
							<app-button class="cell" size="s" value="1">1</app-button>
							<app-button class="cell" size="s" value="2">2</app-button>
						</app-control-group>
					</div>
					<div class="block">
						<div class="row caption">Aspect ratio</div>
						<app-control-group formControlName="aspectRatio" class="row">
							<app-button class="cell" size="s" value="9:16">9:16</app-button>
							<app-button class="cell" size="s" value="3:4">3:4</app-button>
							<app-button class="cell" size="s" value="1:1">1:1</app-button>
							<app-button class="cell" size="s" value="4:3">4:3</app-button>
							<app-button class="cell" size="s" value="16:9">16:9</app-button>
						</app-control-group>
					</div>
					<div class="block">
						<div class="row caption">Seed</div>
						<div class="row">
							<div class="cell"><app-input formControlName="seed"></app-input></div>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-scrollbar>
