export enum BoardNodeTypeEnum {
	ImageCreation = 1,
	StickyNote = 2,
}

export enum BoardNodeDetailedTypeEnum {
	TextToImage = 1,
	Upscale = 2,
	Inpaint = 3,
	Outpaint = 4,
	Vary = 5,
	StickyNote = 6,
}
