import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChildren, forwardRef, QueryList } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ISelectableControl, SELECTABLE_CONTROL } from '../../interfaces/selectable-control';

@Component({
	selector: 'app-control-group',
	template: `<ng-content></ng-content>`,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ControlGroupComponent),
			multi: true,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlGroupComponent implements ControlValueAccessor, AfterContentInit {

	@ContentChildren(SELECTABLE_CONTROL, { descendants: true })
	controls!: QueryList<ISelectableControl>;

	private _value: any = null;
	touched = false;
	disabled = false;

	private onChange = (value: any) => { };
	private onTouched = () => { };

	set value(value: any) {
		this._value = value;
		this.updateControls();
	}

	get value(): any {
		return this._value;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	writeValue(value: any): void {
		this._value = value;
		this.updateControls();
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
		if (this.controls) {
			this.controls.forEach((control) => control.setDisabledState(isDisabled));
		}
	}

	ngAfterContentInit(): void {
		this.controls.forEach((control) => {
			control.registerOnSelect((value: any) => this.updateValueFromControl(value));
			control.setGroupValue(this._value);
			control.setDisabledState(this.disabled);
		});
	}

	private updateValueFromControl(value: any): void {
		if (value !== this._value) {
			this._value = value;
			this.updateControls();
			this.onChange(value);
		}
	}

	private updateControls(): void {
		if (this.controls) {
			this.controls.forEach((control) => {
				control.setGroupValue(this._value);
			});
		}
	}
}
