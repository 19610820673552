import { ChangeDetectionStrategy, Component, Inject, inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

export interface ErrorMessageData {
	title: string;
	message?: string;
}

@Component({
    selector: 'app-error-message',
    imports: [],
    templateUrl: './error-message.component.html',
    styleUrl: './error-message.component.less',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorMessageComponent {
	snackBarRef = inject(MatSnackBarRef);

	constructor(@Inject(MAT_SNACK_BAR_DATA) public data: ErrorMessageData) {

	}
}
