import { ChangeDetectionStrategy, ChangeDetectorRef, Component, effect, inject, signal } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslocoModule } from '@jsverse/transloco';
import { AutoFocusDirective } from '../../../../shared/directives/auto-focus.directive';
import { ImagingService } from '../../services/imagine.service';
import { SuppressAnyErrorStateMatcher } from '../../../../shared/material/error-state-matchers/suppress-any-error';
import { ImagingProviderEnum } from '../../models/discriminators';
import { CreateGenerateImageTaskRequest } from '../../models/generate-image-task.models';
import { finalize } from 'rxjs';
import { NgFor, NgIf } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { ImageGridComponent, ImageGridParams } from '../../../image-library/components/image-grid/image-grid.component';
import { UploadedImage } from '../../../image-library/models/uploaded-image.models';
import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { GenerateImageTaskVisualReferenceRoleEnum } from '../../models/enumerations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { HintTriggerComponent } from "../../../../shared/components/hint-trigger/hint-trigger.component";
import { MagicPromptComponent } from '../../../assistants/components/magic-prompt/magic-prompt.component';
import { ViewImageDirective } from '../../../../shared/directives/view-image.directive';

type AspectRatio = "9:16" | "3:4" | "1:1" | "4:3" | "16:9";
type ImageSize = "small" | "normal" | "big";

@Component({
	selector: 'app-generate-image-task-form',
	imports: [
		MatCardModule,
		MatButtonModule,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		AutoFocusDirective,
		MatProgressBarModule,
		TranslocoModule,
		MatIconModule,
		NgIf,
		MatRadioModule,
		MatSliderModule,
		DialogModule,
		NgFor,
		MatTooltipModule,
		MatSelectModule,
		MatMenuModule,
		MatCheckboxModule,
		HintTriggerComponent,
		MagicPromptComponent,
		ViewImageDirective
	],
	templateUrl: './generate-image-task-form.component.html',
	styleUrl: './generate-image-task-form.component.less',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenerateImageTaskFormComponent {

	private readonly _imagingService = inject(ImagingService);
	private readonly _dialog = inject(Dialog);
	private readonly _changeDetector = inject(ChangeDetectorRef);

	loading = signal(false);
	taskDetails = signal(false);

	imageToUpscaleUrl = signal<string | null>(null);

	suppressAnyErrorStateMatcher = new SuppressAnyErrorStateMatcher();

	ImagingProviderEnum = ImagingProviderEnum;
	GenerateImageTaskVisualReferenceRoleEnum = GenerateImageTaskVisualReferenceRoleEnum;

	aspectRatio: AspectRatio = "1:1";
	imageSize: ImageSize = "normal";

	readonly form = new FormGroup({
		__type: new FormControl<ImagingProviderEnum>(ImagingProviderEnum.SD, [Validators.required]),
		prompt: new FormControl<string | null>(null),
		negativePrompt: new FormControl<string | null>(null),

		visualReferences: new FormArray<any>([]),

		stylize: new FormControl<number>(100),
		weird: new FormControl<number>(0),
		chaos: new FormControl<number>(0),
		quality: new FormControl<string | null>("1"),
		aspectRatio: new FormControl<string | null>("1:1"),
		seed: new FormControl<number | null>(null),
		tile: new FormControl<boolean>(false),

		imagePrompt: new FormControl<string | null>(null),
		imageWeight: new FormControl<number>(1),
		styleReference: new FormControl<string | null>(null),
		styleWeight: new FormControl<number>(100),
		characterReference: new FormControl<string | null>(null),
		characterWeight: new FormControl<number>(100),

		numberOfImages: new FormControl<number>(1),
		width: new FormControl<number>(1024),
		height: new FormControl<number>(1024),
		numberOfInferenceSteps: new FormControl<number>(50),
		guidanceScale: new FormControl<number>(5),

		scaleFactor: new FormControl<number>(2),
		uploadedImageToUpscaleId: new FormControl<number | null>(null),
		generatedImageToUpscaleId: new FormControl<number | null>(null)
	});

	constructor() {
		effect(() => {
			if (this.loading()) {
				this.form.disable();
			}
			else {
				this.form.enable();
			}
		});

		this.onTypeChange();
	}

	resetForm() {
		this.form.reset();
		this.form.controls.__type.setValue(ImagingProviderEnum.SD);
		this.form.controls.stylize.setValue(100);
		this.form.controls.weird.setValue(0);
		this.form.controls.chaos.setValue(0);
		this.form.controls.quality.setValue("1");
		this.form.controls.aspectRatio.setValue("1:1");
		this.form.controls.imageWeight.setValue(1);
		this.form.controls.styleWeight.setValue(100);
		this.form.controls.characterWeight.setValue(100);
		this.form.controls.tile.setValue(false);
		this.form.controls.numberOfImages.setValue(1);
		this.form.controls.width.setValue(1024);
		this.form.controls.height.setValue(1024);
		this.form.controls.numberOfInferenceSteps.setValue(50);
		this.form.controls.guidanceScale.setValue(5);

		this.form.controls.scaleFactor.setValue(2);

		this.form.controls.visualReferences.clear();

		this.recalculateDimensions();
	}

	onTypeChange() {
		const type = this.form.controls.__type.value;

		if (type === ImagingProviderEnum.Upscale) {
			this.form.controls.scaleFactor.setValidators([Validators.required]);
			this.form.controls.uploadedImageToUpscaleId.setValidators([Validators.required]);

			this.form.controls.prompt.removeValidators([Validators.required]);
		}
		else {
			this.form.controls.prompt.setValidators([Validators.required]);

			this.form.controls.scaleFactor.removeValidators([Validators.required]);
			this.form.controls.uploadedImageToUpscaleId.removeValidators([Validators.required]);
		}

		this.form.controls.prompt.updateValueAndValidity();
	}

	toggleTaskDetails() {
		const state = this.taskDetails();
		this.taskDetails.set(!state);
	}

	recalculateDimensions() {
		let width = 1024;
		let height = 1024;

		if (this.imageSize === 'small') {
			if (this.aspectRatio === "9:16") {
				width = 540;
				height = 960;
			}
			else if (this.aspectRatio === "16:9") {
				width = 960;
				height = 540;
			}
			else if (this.aspectRatio === "3:4") {
				width = 600;
				height = 800;
			}
			else if (this.aspectRatio === "4:3") {
				width = 800;
				height = 600;
			}
			else if (this.aspectRatio === "1:1") {
				width = 512;
				height = 512;
			}
		}
		else if (this.imageSize === 'normal') {
			if (this.aspectRatio === "9:16") {
				width = 720;
				height = 1280;
			}
			else if (this.aspectRatio === "16:9") {
				width = 1280;
				height = 720;
			}
			else if (this.aspectRatio === "3:4") {
				width = 768;
				height = 1024;
			}
			else if (this.aspectRatio === "4:3") {
				width = 1024;
				height = 768;
			}
			else if (this.aspectRatio === "1:1") {
				width = 1024;
				height = 1024;
			}
		}
		else if (this.imageSize === 'big') {
			if (this.aspectRatio === "9:16") {
				width = 1080;
				height = 1920;
			}
			else if (this.aspectRatio === "16:9") {
				width = 1920;
				height = 1080;
			}
			else if (this.aspectRatio === "3:4") {
				width = 1536;
				height = 2048;
			}
			else if (this.aspectRatio === "4:3") {
				width = 2048;
				height = 1536;
			}
			else if (this.aspectRatio === "1:1") {
				width = 2048;
				height = 2048;
			}
		}

		this.form.controls.width.setValue(width);
		this.form.controls.height.setValue(height);
	}

	selectImageToUpscale() {
		const dialogRef = this._dialog.open<UploadedImage>(ImageGridComponent, {
			data: <ImageGridParams>{
				isSelectable: true
			}
		});
		dialogRef.closed.subscribe(v => {
			if (!v) {
				return;
			}
			this.form.controls.uploadedImageToUpscaleId.setValue(v.id);
			this.imageToUpscaleUrl.set(v.mediaLink);
		});
	}

	showImageLibrary() {
		const dialogRef = this._dialog.open<UploadedImage>(ImageGridComponent, {
			data: <ImageGridParams>{
				isSelectable: true
			}
		});
		dialogRef.closed.subscribe(v => {
			if (!v) {
				return;
			}
			this.form.controls.visualReferences.push(new FormGroup({
				roleId: new FormControl<GenerateImageTaskVisualReferenceRoleEnum>(GenerateImageTaskVisualReferenceRoleEnum.Style),
				isStyleReferenceCode: new FormControl<boolean>(false),
				externalUrl: new FormControl<string | null>(null),
				uploadedImageId: new FormControl<number>(v.id),
				imageUrl: new FormControl<string>(v.mediaLink),
				weight: new FormControl<number | null>(null)
			}));
			this._changeDetector.markForCheck();
		});
	}

	addStyleReferenceCode() {
		this.form.controls.visualReferences.push(new FormGroup({
			roleId: new FormControl<GenerateImageTaskVisualReferenceRoleEnum>(GenerateImageTaskVisualReferenceRoleEnum.Style),
			isStyleReferenceCode: new FormControl<boolean>(true),
			externalUrl: new FormControl<string | null>(null, [Validators.required, Validators.min(1), Validators.pattern('^(0|[1-9][0-9]*)$')]),
			uploadedImageId: new FormControl<number | null>(null),
			weight: new FormControl<number | null>(null)
		}));
	}

	check(e: Event) {
		e.preventDefault();
		e.stopImmediatePropagation();
		return false;
	}

	removeVisualReference(index: number) {
		this.form.controls.visualReferences.removeAt(index);
	}

	submit() {
		if (this.form.invalid) {
			return;
		}

		this.loading.set(true);

		const request = CreateGenerateImageTaskRequest.fromPlain(<CreateGenerateImageTaskRequest>this.form.value);

		this._imagingService.createGenerateImageTask(request).pipe(
			finalize(() => this.loading.set(false))
		).subscribe(v => {
			//this.resetForm();
		});
	}
}
