import { inject, Injectable, signal } from '@angular/core';
import { Observable, map, tap } from 'rxjs';
import { CreateGenerateImageTaskRequest, GenerateImageTask } from '../models/generate-image-task.models';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiPagedResponse, ApiResponse } from '../../../core/models/api/api.models';
import { WORKSPACE_API_URL_PREFIX } from '../../../shared/constants/api.constants';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { CreateImageCreationTemplateVisualReferenceRequest, ImageCreationTemplate, ImageCreationTemplateVisualReference, ProcessImageCreationTemplateRequest, UpdateImageCreationTemplateCurrentGeneratedImageRequest, UpdateImageCreationTemplatePromptRequest, UpdateImageCreationTemplateRequest, UpdateImageCreationTemplateVisualReferenceRequest } from '../models/image-creation-template.models';
import { UpdateGenerateImageTaskTemplateRequest, GenerateImageTaskTemplate, UpdateGenerateImageTaskTemplatePromptRequest } from '../models/generate-image-task-template.models';

@Injectable({
	providedIn: 'root'
})
export class ImagingService {

	private readonly _http = inject(HttpClient);

	private readonly _newGenerateImageTask = signal<GenerateImageTask | undefined>(undefined);
	newGenerateImageTask = this._newGenerateImageTask.asReadonly();

	getGenerateImageTaskById(id: number): Observable<GenerateImageTask> {
		return this._http.get<ApiResponse<GenerateImageTask>>(`${WORKSPACE_API_URL_PREFIX}/api/imaging/tasks/${id}`).pipe(
			map(v => GenerateImageTask.fromPlain(v.data))
		);
	}

	public getGenerateImageTasks(pageNumber: number, pageSize: number): Observable<ApiPagedResponse<GenerateImageTask>> {
		let params = new HttpParams()
			.set('pageNumber', pageNumber)
			.set('pageSize', pageSize);
		return this._http.get<ApiPagedResponse<GenerateImageTask>>(`${WORKSPACE_API_URL_PREFIX}/api/imaging/tasks`, { params: params }).pipe(
			map(v => {
				return {
					...v,
					data: v.data.map(i => GenerateImageTask.fromPlain(i))
				};
			})
		);
	}

	createGenerateImageTask(request: CreateGenerateImageTaskRequest): Observable<GenerateImageTask> {
		const body = instanceToPlain(request);
		return this._http.post<ApiResponse<GenerateImageTask>>(`${WORKSPACE_API_URL_PREFIX}/api/imaging/tasks`, body).pipe(
			map(v => GenerateImageTask.fromPlain(v.data)),
			tap(v => {
				this._newGenerateImageTask.set(v);
			})
		);
	}

	// Template Visual References

	createVisualReference(request: CreateImageCreationTemplateVisualReferenceRequest): Observable<ImageCreationTemplateVisualReference> {
		const body = instanceToPlain(request);
		return this._http.post<ApiResponse<ImageCreationTemplateVisualReference>>(`${WORKSPACE_API_URL_PREFIX}/api/imaging/templates/visual-references`, body).pipe(
			map(v => plainToInstance(ImageCreationTemplateVisualReference, v.data))
		);
	}

	updateVisualReference(request: UpdateImageCreationTemplateVisualReferenceRequest): Observable<ImageCreationTemplateVisualReference> {
		const body = instanceToPlain(request);
		return this._http.put<ApiResponse<ImageCreationTemplateVisualReference>>(`${WORKSPACE_API_URL_PREFIX}/api/imaging/templates/visual-references`, body).pipe(
			map(v => plainToInstance(ImageCreationTemplateVisualReference, v.data))
		);
	}

	deleteVisualReference(id: number): Observable<void> {
		return this._http.delete<void>(`${WORKSPACE_API_URL_PREFIX}/api/imaging/templates/visual-references/${id}`);
	}

	// Template & Task Template

	UpdateTemplate(request: UpdateImageCreationTemplateRequest): Observable<ImageCreationTemplate> {
		const body = instanceToPlain(request);
		return this._http.put<ApiResponse<ImageCreationTemplate>>(`${WORKSPACE_API_URL_PREFIX}/api/imaging/templates`, body).pipe(
			map(v => ImageCreationTemplate.fromPlain(v.data))
		);
	}

	UpdateTaskTemplate(request: UpdateGenerateImageTaskTemplateRequest): Observable<GenerateImageTaskTemplate> {
		const body = instanceToPlain(request);
		return this._http.put<ApiResponse<GenerateImageTaskTemplate>>(`${WORKSPACE_API_URL_PREFIX}/api/imaging/templates/tasks`, body).pipe(
			map(v => GenerateImageTaskTemplate.fromPlain(v.data))
		);
	}

	UpdateTaskTemplatePrompt(request: UpdateGenerateImageTaskTemplatePromptRequest): Observable<void> {
		const body = instanceToPlain(request);
		return this._http.patch<void>(`${WORKSPACE_API_URL_PREFIX}/api/imaging/templates/tasks/prompt`, body);
	}

	UpdateTemplatePrompt(request: UpdateImageCreationTemplatePromptRequest): Observable<void> {
		const body = instanceToPlain(request);
		return this._http.patch<void>(`${WORKSPACE_API_URL_PREFIX}/api/imaging/templates/prompt`, body);
	}

	ProcessTemplate(request: ProcessImageCreationTemplateRequest): Observable<void> {
		const body = instanceToPlain(request);
		return this._http.post<void>(`${WORKSPACE_API_URL_PREFIX}/api/imaging/templates/process`, body);
	}

	UpdateTemplateCurrentImage(request: UpdateImageCreationTemplateCurrentGeneratedImageRequest): Observable<void> {
		const body = instanceToPlain(request);
		return this._http.patch<void>(`${WORKSPACE_API_URL_PREFIX}/api/imaging/templates/current-image`, body);
	}

}
