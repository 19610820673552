import { Expose, plainToInstance, Type } from "class-transformer";
import { ImageCreationTemplateTypeEnum } from "./discriminators";
import { GenerateImageTaskVisualReferenceRoleEnum } from "./enumerations";
import { UploadedImage } from "../../image-library/models/uploaded-image.models";
import { GenerateMidjourneyImageTaskTemplate, GenerateSDImageTaskTemplate, UpscaleImageTaskTemplate } from "./generate-image-task-template.models";
import { GeneratedImage } from "./generated-image.models";

export abstract class ImageCreationTemplate {
	@Expose() __type!: ImageCreationTemplateTypeEnum;

	@Expose() id!: number;
	@Expose() prompt!: string;
	@Expose() negativePrompt?: string;

	@Expose() @Type(() => GeneratedImage) currentGeneratedImage?: GeneratedImage;

	@Expose() @Type(() => ImageCreationTemplateVisualReference) visualReferences: ImageCreationTemplateVisualReference[] = [];

	static fromPlain(object: ImageCreationTemplate): ImageCreationTemplate {
		if (object.__type === ImageCreationTemplateTypeEnum.TextToImage) {
			return plainToInstance(TextToImageTemplate, object, { excludeExtraneousValues: true });
		}
		else if (object.__type === ImageCreationTemplateTypeEnum.Upscale) {
			return plainToInstance(UpscaleTemplate, object, { excludeExtraneousValues: true });
		}
		else if (object.__type === ImageCreationTemplateTypeEnum.Inpaint) {
			return plainToInstance(InpaintTemplate, object, { excludeExtraneousValues: true });
		}
		else if (object.__type === ImageCreationTemplateTypeEnum.Outpaint) {
			return plainToInstance(OutpaintTemplate, object, { excludeExtraneousValues: true });
		}
		else if (object.__type === ImageCreationTemplateTypeEnum.Vary) {
			return plainToInstance(VaryTemplate, object, { excludeExtraneousValues: true });
		}
		throw new Error("Unknown object type");
	}
}

export class TextToImageTemplate extends ImageCreationTemplate {

	@Expose() @Type(() => GenerateMidjourneyImageTaskTemplate) midjourneyTaskTemplate!: GenerateMidjourneyImageTaskTemplate;
	@Expose() @Type(() => GenerateSDImageTaskTemplate) sdTaskTemplate!: GenerateSDImageTaskTemplate;

	constructor() {
		super();
		this.__type = ImageCreationTemplateTypeEnum.TextToImage;
	}
}

export class UpscaleTemplate extends ImageCreationTemplate {

	@Expose() @Type(() => UpscaleImageTaskTemplate) upscaleTaskTemplate!: UpscaleImageTaskTemplate;

	constructor() {
		super();
		this.__type = ImageCreationTemplateTypeEnum.Upscale;
	}
}

export class InpaintTemplate extends ImageCreationTemplate {

	constructor() {
		super();
		this.__type = ImageCreationTemplateTypeEnum.Inpaint;
	}
}

export class OutpaintTemplate extends ImageCreationTemplate {

	constructor() {
		super();
		this.__type = ImageCreationTemplateTypeEnum.Outpaint;
	}
}

export class VaryTemplate extends ImageCreationTemplate {

	constructor() {
		super();
		this.__type = ImageCreationTemplateTypeEnum.Vary;
	}
}

// ------------------------------------

export class ImageCreationTemplateVisualReference {
	@Expose() id!: number;
	@Expose() roleId!: GenerateImageTaskVisualReferenceRoleEnum;
	@Expose() externalUrl?: string;
	@Expose() @Type(() => UploadedImage) uploadedImage?: UploadedImage;
	@Expose() weight?: number;
	@Expose() @Type(() => ImageCreationTemplate, {
		discriminator: {
			property: '__type',
			subTypes: [
				{ value: TextToImageTemplate, name: ImageCreationTemplateTypeEnum.TextToImage as unknown as string },
				{ value: UpscaleTemplate, name: ImageCreationTemplateTypeEnum.Upscale as unknown as string },
				{ value: InpaintTemplate, name: ImageCreationTemplateTypeEnum.Inpaint as unknown as string },
				{ value: OutpaintTemplate, name: ImageCreationTemplateTypeEnum.Outpaint as unknown as string },
				{ value: VaryTemplate, name: ImageCreationTemplateTypeEnum.Vary as unknown as string },
			],
		},
		keepDiscriminatorProperty: true,
	})
	template?: ImageCreationTemplate;
}

///// Requests

export abstract class UpdateImageCreationTemplateRequest {
	@Expose() __type!: ImageCreationTemplateTypeEnum;

	@Expose() id!: number;
	@Expose() prompt!: string;
	@Expose() negativePrompt?: string;

	static fromPlain(object: UpdateImageCreationTemplateRequest): UpdateImageCreationTemplateRequest {
		if (object.__type === ImageCreationTemplateTypeEnum.TextToImage) {
			return plainToInstance(UpdateTextToImageTemplateRequest, object, { excludeExtraneousValues: true });
		}
		else if (object.__type === ImageCreationTemplateTypeEnum.Upscale) {
			return plainToInstance(UpdateUpscaleTemplateRequest, object, { excludeExtraneousValues: true });
		}
		else if (object.__type === ImageCreationTemplateTypeEnum.Inpaint) {
			return plainToInstance(UpdateInpaintTemplateRequest, object, { excludeExtraneousValues: true });
		}
		else if (object.__type === ImageCreationTemplateTypeEnum.Outpaint) {
			return plainToInstance(UpdateOutpaintTemplateRequest, object, { excludeExtraneousValues: true });
		}
		else if (object.__type === ImageCreationTemplateTypeEnum.Vary) {
			return plainToInstance(UpdateVaryTemplateRequest, object, { excludeExtraneousValues: true });
		}
		throw new Error("Unknown object type");
	}
}

export class UpdateTextToImageTemplateRequest extends UpdateImageCreationTemplateRequest {

	constructor() {
		super();
		this.__type = ImageCreationTemplateTypeEnum.TextToImage;
	}
}

export class UpdateUpscaleTemplateRequest extends UpdateImageCreationTemplateRequest {

	constructor() {
		super();
		this.__type = ImageCreationTemplateTypeEnum.Upscale;
	}
}

export class UpdateInpaintTemplateRequest extends UpdateImageCreationTemplateRequest {

	constructor() {
		super();
		this.__type = ImageCreationTemplateTypeEnum.Inpaint;
	}
}

export class UpdateOutpaintTemplateRequest extends UpdateImageCreationTemplateRequest {

	constructor() {
		super();
		this.__type = ImageCreationTemplateTypeEnum.Outpaint;
	}
}

export class UpdateVaryTemplateRequest extends UpdateImageCreationTemplateRequest {

	constructor() {
		super();
		this.__type = ImageCreationTemplateTypeEnum.Vary;
	}
}

// ------------------------------------

export class CreateImageCreationTemplateVisualReferenceRequest {
	@Expose() roleId!: GenerateImageTaskVisualReferenceRoleEnum;
	@Expose() externalUrl?: string;
	@Expose() uploadedImageId?: number;
	@Expose() weight?: number;
	@Expose() templateId!: number;
}

export class UpdateImageCreationTemplateVisualReferenceRequest {
	@Expose() id!: number;
	@Expose() weight?: number;
}

export class UpdateImageCreationTemplatePromptRequest {
	@Expose() id!: number;
	@Expose() prompt?: string;
	@Expose() negativePrompt?: string;
}

export class ProcessImageCreationTemplateRequest {
	@Expose() id!: number;
}

export class UpdateImageCreationTemplateCurrentGeneratedImageRequest {
	@Expose() id!: number;
	@Expose() generatedImageId!: number;
}
