import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-page-wrapper',
    imports: [],
    templateUrl: './page-wrapper.component.html',
    styleUrl: './page-wrapper.component.less',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageWrapperComponent {

}
