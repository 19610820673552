import { Expose, plainToInstance, Type } from "class-transformer";
import { User } from "../../users/models/user.models";
import { ImageCreationTemplateTypeEnum } from "../../imaging/models/discriminators";
import { ImageCreationTemplate, InpaintTemplate, OutpaintTemplate, TextToImageTemplate, UpscaleTemplate, VaryTemplate } from "../../imaging/models/image-creation-template.models";
import { BoardNodeDetailedTypeEnum, BoardNodeTypeEnum } from "./discriminators";

export abstract class BoardNode {
	@Expose() __type!: BoardNodeTypeEnum;

	@Expose() id!: number;
	@Expose() x!: number;
	@Expose() y!: number;
	@Expose() @Type(() => User) owner?: User;

	static fromPlain(object: BoardNode): BoardNode {
		if (object.__type === BoardNodeTypeEnum.ImageCreation) {
			return plainToInstance(ImageCreationNode, object, { excludeExtraneousValues: true });
		}
		else if (object.__type === BoardNodeTypeEnum.StickyNote) {
			return plainToInstance(StickyNoteNode, object, { excludeExtraneousValues: true });
		}
		throw new Error("Unknown object type");
	}
}

export class ImageCreationNode extends BoardNode {
	@Expose() @Type(() => ImageCreationTemplate, {
		discriminator: {
			property: '__type',
			subTypes: [
				{ value: TextToImageTemplate, name: ImageCreationTemplateTypeEnum.TextToImage as unknown as string },
				{ value: UpscaleTemplate, name: ImageCreationTemplateTypeEnum.Upscale as unknown as string },
				{ value: InpaintTemplate, name: ImageCreationTemplateTypeEnum.Inpaint as unknown as string },
				{ value: OutpaintTemplate, name: ImageCreationTemplateTypeEnum.Outpaint as unknown as string },
				{ value: VaryTemplate, name: ImageCreationTemplateTypeEnum.Vary as unknown as string },
			],
		},
		keepDiscriminatorProperty: true,
	})
	template!: ImageCreationTemplate;

	constructor() {
		super();
		this.__type = BoardNodeTypeEnum.ImageCreation;
	}
}
export class StickyNoteNode extends BoardNode {
	@Expose() text?: string;

	constructor() {
		super();
		this.__type = BoardNodeTypeEnum.StickyNote;
	}
}

///// Requests

export abstract class CreateBoardNodeRequest {
	@Expose() __type!: BoardNodeDetailedTypeEnum;

	@Expose() x!: number;
	@Expose() y!: number;
	@Expose() boardId!: number;

	static fromPlain(object: CreateBoardNodeRequest): CreateBoardNodeRequest {
		if (object.__type === BoardNodeDetailedTypeEnum.TextToImage) {
			return plainToInstance(CreateTextToImageBoardNodeRequest, object, { excludeExtraneousValues: true });
		}
		else if (object.__type === BoardNodeDetailedTypeEnum.Upscale) {
			return plainToInstance(CreateUpscaleBoardNodeRequest, object, { excludeExtraneousValues: true });
		}
		else if (object.__type === BoardNodeDetailedTypeEnum.Inpaint) {
			return plainToInstance(CreateInpaintBoardNodeRequest, object, { excludeExtraneousValues: true });
		}
		else if (object.__type === BoardNodeDetailedTypeEnum.Outpaint) {
			return plainToInstance(CreateOutpaintBoardNodeRequest, object, { excludeExtraneousValues: true });
		}
		else if (object.__type === BoardNodeDetailedTypeEnum.Vary) {
			return plainToInstance(CreateVaryBoardNodeRequest, object, { excludeExtraneousValues: true });
		}
		else if (object.__type === BoardNodeDetailedTypeEnum.StickyNote) {
			return plainToInstance(CreateStickyNoteBoardNodeRequest, object, { excludeExtraneousValues: true });
		}
		throw new Error("Unknown object type");
	}
}

export class CreateTextToImageBoardNodeRequest extends CreateBoardNodeRequest {

	constructor() {
		super();
		this.__type = BoardNodeDetailedTypeEnum.TextToImage;
	}
}

export class CreateUpscaleBoardNodeRequest extends CreateBoardNodeRequest {

	constructor() {
		super();
		this.__type = BoardNodeDetailedTypeEnum.Upscale;
	}
}

export class CreateInpaintBoardNodeRequest extends CreateBoardNodeRequest {

	constructor() {
		super();
		this.__type = BoardNodeDetailedTypeEnum.Inpaint;
	}
}

export class CreateOutpaintBoardNodeRequest extends CreateBoardNodeRequest {

	constructor() {
		super();
		this.__type = BoardNodeDetailedTypeEnum.Outpaint;
	}
}

export class CreateVaryBoardNodeRequest extends CreateBoardNodeRequest {

	constructor() {
		super();
		this.__type = BoardNodeDetailedTypeEnum.Vary;
	}
}

export class CreateStickyNoteBoardNodeRequest extends CreateBoardNodeRequest {
	@Expose() text?: string;

	constructor() {
		super();
		this.__type = BoardNodeDetailedTypeEnum.StickyNote;
	}
}

////////////////

export abstract class UpdateBoardNodeRequest {
	@Expose() __type!: BoardNodeDetailedTypeEnum;

	@Expose() x!: number;
	@Expose() y!: number;
	@Expose() boardId!: number;

	static fromPlain(object: UpdateBoardNodeRequest): UpdateBoardNodeRequest {
		if (object.__type === BoardNodeDetailedTypeEnum.TextToImage) {
			return plainToInstance(UpdateTextToImageBoardNodeRequest, object, { excludeExtraneousValues: true });
		}
		else if (object.__type === BoardNodeDetailedTypeEnum.Upscale) {
			return plainToInstance(UpdateUpscaleBoardNodeRequest, object, { excludeExtraneousValues: true });
		}
		else if (object.__type === BoardNodeDetailedTypeEnum.Inpaint) {
			return plainToInstance(UpdateInpaintBoardNodeRequest, object, { excludeExtraneousValues: true });
		}
		else if (object.__type === BoardNodeDetailedTypeEnum.Outpaint) {
			return plainToInstance(UpdateOutpaintBoardNodeRequest, object, { excludeExtraneousValues: true });
		}
		else if (object.__type === BoardNodeDetailedTypeEnum.Vary) {
			return plainToInstance(UpdateVaryBoardNodeRequest, object, { excludeExtraneousValues: true });
		}
		else if (object.__type === BoardNodeDetailedTypeEnum.StickyNote) {
			return plainToInstance(UpdateStickyNoteBoardNodeRequest, object, { excludeExtraneousValues: true });
		}
		throw new Error("Unknown object type");
	}
}

export class UpdateTextToImageBoardNodeRequest extends UpdateBoardNodeRequest {

	constructor() {
		super();
		this.__type = BoardNodeDetailedTypeEnum.TextToImage;
	}
}

export class UpdateUpscaleBoardNodeRequest extends UpdateBoardNodeRequest {

	constructor() {
		super();
		this.__type = BoardNodeDetailedTypeEnum.Upscale;
	}
}

export class UpdateInpaintBoardNodeRequest extends UpdateBoardNodeRequest {

	constructor() {
		super();
		this.__type = BoardNodeDetailedTypeEnum.Inpaint;
	}
}

export class UpdateOutpaintBoardNodeRequest extends UpdateBoardNodeRequest {

	constructor() {
		super();
		this.__type = BoardNodeDetailedTypeEnum.Outpaint;
	}
}

export class UpdateVaryBoardNodeRequest extends UpdateBoardNodeRequest {

	constructor() {
		super();
		this.__type = BoardNodeDetailedTypeEnum.Vary;
	}
}

export class UpdateStickyNoteBoardNodeRequest extends UpdateBoardNodeRequest {
	@Expose() text?: string;

	constructor() {
		super();
		this.__type = BoardNodeDetailedTypeEnum.StickyNote;
	}
}


////////////////

export class UpdateBoardNodeTransformationRequest {
	@Expose() id!: number;
	@Expose() x!: number;
	@Expose() y!: number;
}
