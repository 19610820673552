import { booleanAttribute, ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
	selector: 'app-icon',
	imports: [
		AngularSvgIconModule
	],
	templateUrl: './icon.component.html',
	styleUrl: './icon.component.less',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent {

	@Input() src!: string;
	@Input() size: number = 14;

	@HostBinding("class.no-recolor")
	@Input({ transform: booleanAttribute })
	noRecolor = false;

	@HostBinding("style.--app-icon-size")
	get sizeVariable() {
		return `${this.size}px`;
	}
}
