import { Expose, plainToInstance, Type } from "class-transformer";
import { ImagingProviderEnum } from "./discriminators";
import { GenerateImageTaskVisualReferenceRoleEnum, GenerateImageTaskStatusEnum, GenerateImageWorkflowStatusEnum, PostprocessGenerateImageResultStatusEnum } from "./enumerations";
import { GenerateImageResult, GenerateMidjourneyImageResult, GenerateSDImageResult, UpscaleImageResult } from "./generate-image-result.models";
import { UploadedImage } from "../../image-library/models/uploaded-image.models";
import { GeneratedImage } from "./generated-image.models";

export abstract class GenerateImageTask {
	@Expose() __type!: ImagingProviderEnum;

	@Expose() id!: number;
	@Expose() prompt?: string;
	@Expose() negativePrompt?: string;
	@Expose() statusId!: GenerateImageTaskStatusEnum;
	@Expose() completedAt!: Date;
	@Expose() @Type(() => GenerateImageResult, {
		discriminator: {
			property: '__type',
			subTypes: [
				{ value: GenerateMidjourneyImageResult, name: ImagingProviderEnum.Midjourney as unknown as string },
				{ value: GenerateSDImageResult, name: ImagingProviderEnum.SD as unknown as string },
				{ value: UpscaleImageResult, name: ImagingProviderEnum.Upscale as unknown as string }
			],
		},
		keepDiscriminatorProperty: true,
	})
	result?: GenerateImageResult;
	@Expose() @Type(() => GenerateImageTaskVisualReference) visualReferences: GenerateImageTaskVisualReference[] = [];

	static fromPlain(object: GenerateImageTask): GenerateImageTask {
		if (object.__type === ImagingProviderEnum.Midjourney) {
			const task = plainToInstance(GenerateMidjourneyImageTask, object, { excludeExtraneousValues: true });
			return task;
		}
		else if (object.__type === ImagingProviderEnum.SD) {
			const task = plainToInstance(GenerateSDImageTask, object, { excludeExtraneousValues: true });
			return task;
		}
		else if (object.__type === ImagingProviderEnum.Upscale) {
			const task = plainToInstance(UpscaleImageTask, object, { excludeExtraneousValues: true });
			return task;
		}
		throw new Error("Unknown object type");
	}

	get workflowStatusId(): GenerateImageWorkflowStatusEnum {
		if (this.statusId === GenerateImageTaskStatusEnum.Failed || this.result?.postprocessStatusId === PostprocessGenerateImageResultStatusEnum.Failed) {
			return GenerateImageWorkflowStatusEnum.Failed;
		}
		else if (this.result?.postprocessStatusId === PostprocessGenerateImageResultStatusEnum.Succeeded) {
			return GenerateImageWorkflowStatusEnum.Succeeded;
		}
		return GenerateImageWorkflowStatusEnum.Processing;
	}
}

export class GenerateMidjourneyImageTask extends GenerateImageTask {
	@Expose() aspectRatio?: string;
	@Expose() chaos?: number;
	@Expose() quality?: string;
	@Expose() seed?: string;
	@Expose() characterReference?: string;
	@Expose() characterWeight?: number;
	@Expose() imagePrompt?: string;
	@Expose() imageWeight?: number;
	@Expose() style?: string;
	@Expose() styleReference?: string;
	@Expose() styleWeight?: number;
	@Expose() stylize?: number;
	@Expose() tile?: boolean;
	@Expose() weird?: number;
	@Expose() version?: string;

	constructor() {
		super();
		this.__type = ImagingProviderEnum.Midjourney;
	}
}

export class GenerateSDImageTask extends GenerateImageTask {
	@Expose() numberOfImages?: number;
	@Expose() width?: number;
	@Expose() height?: number;
	@Expose() numberOfInferenceSteps?: number;
	@Expose() guidanceScale?: number;

	constructor() {
		super();
		this.__type = ImagingProviderEnum.SD;
	}
}

export class UpscaleImageTask extends GenerateImageTask {
	@Expose() scaleFactor!: number;
	@Expose() @Type(() => UploadedImage) uploadedImageToUpscale!: UploadedImage;
	@Expose() @Type(() => GeneratedImage) generatedImageToUpscale!: GeneratedImage;

	constructor() {
		super();
		this.__type = ImagingProviderEnum.Upscale;
	}
}

export class GenerateImageTaskVisualReference {
	@Expose() id!: number;
	@Expose() roleId!: GenerateImageTaskVisualReferenceRoleEnum;
	@Expose() externalUrl?: string;
	@Expose() @Type(() => UploadedImage) uploadedImage?: UploadedImage;
	@Expose() weight?: number;
	@Expose() @Type(() => GenerateImageTask, {
		discriminator: {
			property: '__type',
			subTypes: [
				{ value: GenerateMidjourneyImageTask, name: ImagingProviderEnum.Midjourney as unknown as string },
				{ value: GenerateSDImageTask, name: ImagingProviderEnum.SD as unknown as string },
				{ value: UpscaleImageTask, name: ImagingProviderEnum.Upscale as unknown as string }
			],
		},
		keepDiscriminatorProperty: true,
	})
	task?: GenerateImageTask;
}

///// Requests

export abstract class CreateGenerateImageTaskRequest {
	@Expose() __type!: ImagingProviderEnum;

	@Expose() prompt?: string;
	@Expose() negativePrompt?: string;
	@Expose() @Type(() => GenerateImageTaskVisualReferenceReq) visualReferences: GenerateImageTaskVisualReferenceReq[] = [];

	static fromPlain(object: CreateGenerateImageTaskRequest): CreateGenerateImageTaskRequest {
		if (object.__type === ImagingProviderEnum.Midjourney) {
			return plainToInstance(CreateGenerateMidjourneyImageTaskRequest, object, { excludeExtraneousValues: true });
		}
		else if (object.__type === ImagingProviderEnum.SD) {
			return plainToInstance(CreateGenerateSDImageTaskRequest, object, { excludeExtraneousValues: true });
		}
		else if (object.__type === ImagingProviderEnum.Upscale) {
			return plainToInstance(CreateUpscaleImageTaskRequest, object, { excludeExtraneousValues: true });
		}
		throw new Error("Unknown object type");
	}
}

export class CreateGenerateMidjourneyImageTaskRequest extends CreateGenerateImageTaskRequest {
	@Expose() aspectRatio?: string;
	@Expose() chaos?: number;
	@Expose() quality?: string;
	@Expose() seed?: string;
	@Expose() characterReference?: string;
	@Expose() characterWeight?: number;
	@Expose() imagePrompt?: string;
	@Expose() imageWeight?: number;
	@Expose() style?: string;
	@Expose() styleReference?: string;
	@Expose() styleWeight?: number;
	@Expose() stylize?: number;
	@Expose() tile?: boolean;
	@Expose() weird?: number;
	@Expose() version?: string;

	constructor() {
		super();
		this.__type = ImagingProviderEnum.Midjourney;
	}
}

export class CreateGenerateSDImageTaskRequest extends CreateGenerateImageTaskRequest {
	@Expose() numberOfImages?: number;
	@Expose() width?: number;
	@Expose() height?: number;
	@Expose() numberOfInferenceSteps?: number;
	@Expose() guidanceScale?: number;

	constructor() {
		super();
		this.__type = ImagingProviderEnum.SD;
	}
}

export class CreateUpscaleImageTaskRequest extends CreateGenerateImageTaskRequest {
	@Expose() scaleFactor?: number;
	@Expose() uploadedImageToUpscaleId?: number;
	@Expose() generatedImageToUpscaleId?: number;
	@Expose() imageToUpscaleExternalUrl?: string;

	constructor() {
		super();
		this.__type = ImagingProviderEnum.Upscale;
	}
}

export class GenerateImageTaskVisualReferenceReq {
	@Expose() roleId!: GenerateImageTaskVisualReferenceRoleEnum;
	@Expose() externalUrl?: string;
	@Expose() uploadedImageId?: number;
	@Expose() weight?: number;
}

