import { Expose, Type } from "class-transformer";
import { User } from "../../users/models/user.models";
import { BoardUserRoleEnum } from "./enumerations";
import { BoardNode, ImageCreationNode, StickyNoteNode } from "./board-node.models";
import { BoardNodeTypeEnum } from "./discriminators";

export class Board {
	@Expose() id!: number;
	@Expose() identityCode!: string;
	@Expose() name!: string;
	@Expose() @Type(() => BoardUser) users: BoardUser[] = [];

	@Expose() @Type(() => BoardNode, {
		discriminator: {
			property: '__type',
			subTypes: [
				{ value: ImageCreationNode, name: BoardNodeTypeEnum.ImageCreation as unknown as string },
				{ value: StickyNoteNode, name: BoardNodeTypeEnum.StickyNote as unknown as string }
			],
		},
		keepDiscriminatorProperty: true,
	})
	nodes: BoardNode[] = [];

	@Expose() @Type(() => BoardUser) currentUser!: BoardUser;
}

export class BoardUser {
	@Expose() @Type(() => User) user!: User;
	@Expose() roleId!: BoardUserRoleEnum;
	@Expose() scale!: number;
	@Expose() x!: number;
	@Expose() y!: number;
}

///// Requests

export class CreateBoardRequest {
	@Expose() name!: string;
}

export class UpdateBoardRequest {
	@Expose() id!: number;
	@Expose() name!: string;
}

export class UpdateBoardTransformationRequest {
	@Expose() boardId!: number;
	@Expose() scale!: number;
	@Expose() x!: number;
	@Expose() y!: number;
}

