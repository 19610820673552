import { ChangeDetectionStrategy, Component, computed, effect, inject, Input, OnChanges, signal, SimpleChanges, untracked } from '@angular/core';
import { BoardNode, ImageCreationNode } from '../../models/board-node.models';
import { GlobalSignalingService } from '../../../../shared/services/signaling/global-signaling.service';
import { ImagingService } from '../../../imaging/services/imagine.service';
import { GenerateImageTask } from '../../../imaging/models/generate-image-task.models';
import { TextToImageTemplate } from '../../../imaging/models/image-creation-template.models';
import { GenerateImageWorkflowStatusEnum, PostprocessGenerateImageResultStatusEnum } from '../../../imaging/models/enumerations';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BoardInteractionService } from '../../services/board-interaction.service';
import { GeneratedImage } from '../../../imaging/models/generated-image.models';

@Component({
	selector: 'app-board-node',
	imports: [
		NgIf,
		NgxSkeletonLoaderModule,
		NgTemplateOutlet
	],
	templateUrl: './board-node.component.html',
	styleUrl: './board-node.component.less',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoardNodeComponent implements OnChanges {

	private readonly _signalingService = inject(GlobalSignalingService);
	private readonly _imagingService = inject(ImagingService);
	private readonly _boardInteractionService = inject(BoardInteractionService);

	@Input() node!: BoardNode;
	@Input() selected = false;

	tasks = signal<GenerateImageTask[]>([]);
	loading = signal(false);
	current = signal(false);

	images = computed(() => {
		const md = this.tasks().filter(i => i.result !== undefined).map(i => i.result!.generatedImages).flat();
		return md;
	});
	image = computed(() => {
		const md = this.images();
		const selectedImage = this._boardInteractionService.boardNodeCurrentImage();
		if (this.node instanceof ImageCreationNode) {
			if (selectedImage && selectedImage.node.id === this.node.id) {
				this.node.template.currentGeneratedImage = selectedImage.image;
				return selectedImage.image;
			}
			if (this.node.template.currentGeneratedImage) {
				return this.node.template.currentGeneratedImage;
			}
		}
		if (md.length > 0) {
			return md[0];
		}
		return null;
	});

	constructor() {

		// TASK STATUS

		effect(() => {
			const status = this._signalingService.generateImageTaskStatus();

			if (!status) {
				return;
			}

			if (this.node instanceof ImageCreationNode && this.node.template instanceof TextToImageTemplate) {
				const taskTemplateIds = [this.node.template.midjourneyTaskTemplate.id, this.node.template.sdTaskTemplate.id];
				if (!taskTemplateIds.some(i => i === status.generateImageTaskTemplateId)) {
					return;
				}
				this.loading.set(true);
			}
		});

		// POSTPROCESSING STATUS

		effect(() => {
			const status = this._signalingService.postprocessGenerateImageResultStatus();

			if (!status) {
				return;
			}

			if (this.node instanceof ImageCreationNode && this.node.template instanceof TextToImageTemplate) {
				const taskTemplateIds = [this.node.template.midjourneyTaskTemplate.id, this.node.template.sdTaskTemplate.id];
				if (!taskTemplateIds.some(i => i === status.generateImageTaskTemplateId)) {
					return;
				}
				this.loading.set(true);
				if (status.statusId === PostprocessGenerateImageResultStatusEnum.Succeeded) {
					this._imagingService.getGenerateImageTaskById(status.generateImageTaskId).subscribe(v => {
						this.loading.set(false);
						this.tasks.update(existing => {
							const index = existing.findIndex(i => i.id === v.id);
							if (index !== -1) {
								const updated = [...existing];
								updated[index] = v;
								return updated;
							}
							return [v, ...existing];
						});
					});
				}
			}
		});

		// Announce node tasks

		effect(() => {
			const current = this.current();
			const tasks = this.tasks();

			if (!current) {
				return;
			}

			this._boardInteractionService.setBoardNodeTasks({
				node: this.node,
				tasks: tasks
			});
		});

		effect(() => {
			//console.log({ tasks: this.tasks(), node: this.node });
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes["selected"]) {
			this.current.set(this.selected);
		}
		if (!changes["node"]) {
			return;
		}
		if (this.node instanceof ImageCreationNode && this.node.template instanceof TextToImageTemplate) {

			const md = this.node.template.midjourneyTaskTemplate.tasks;
			const sd = this.node.template.sdTaskTemplate.tasks;

			const tasks = md.concat(sd).sort((a, b) => { return b.id - a.id });

			const pendingTasks = tasks.some(i => i.workflowStatusId !== GenerateImageWorkflowStatusEnum.Succeeded && i.workflowStatusId !== GenerateImageWorkflowStatusEnum.Failed);

			if (pendingTasks) {
				this.loading.set(true);
			}

			/* if (this.node instanceof ImageCreationNode && this.node.template.currentGeneratedImage) {
				this._boardInteractionService.setBoardNodeCurrentImage({
					node: this.node,
					image: this.node.template.currentGeneratedImage
				});
			} */

			this.tasks.set(tasks);
		}
	}

}
