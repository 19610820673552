<div class="wrapper">
	<div class="tasks-wrapper">
		<app-image-creation-node-tasks [node]="node"></app-image-creation-node-tasks>
	</div>
	<form [formGroup]="formTemplate">
		<app-textarea-ng class="textarea" formControlName="prompt" label="Prompt"></app-textarea-ng>
		<app-textarea-ng class="textarea" formControlName="negativePrompt" label="Negative prompt"></app-textarea-ng>
		<div class="footer-wrapper">
			<div class="providers-wrapper">
				<div class="cell">
					<app-slide-toggle formControlName="customizePrompt"></app-slide-toggle>
				</div>
				<div class="cell icon-list-wrapper" *ngIf="formTemplate.controls.customizePrompt.value">
					<app-button type="icon" iconSrc="/icons/stable-diffusion-01.svg"></app-button>
					<app-button type="icon" iconSrc="/icons/midjourney-01.svg"></app-button>
				</div>
			</div>
			<app-button type="accent" size="l" size="l" iconSrc="/icons/magic-wand-01.svg" [disabled]="formTemplate.invalid || loading()" (click)="processTemplate()">Generate</app-button>
		</div>
	</form>
</div>
