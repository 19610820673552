import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonComponent } from '../../../../core/ui-kit/components/button/button.component';
import { RangeSliderComponent } from '../../../../core/ui-kit/components/range-slider/range-slider.component';
import { CheckboxComponent } from '../../../../core/ui-kit/components/checkbox/checkbox.component';
import { InputComponent } from '../../../../core/ui-kit/components/input/input.component';
import { IconComponent } from '../../../../core/ui-kit/components/icon/icon.component';
import { ImagingProviderEnum } from '../../../imaging/models/discriminators';
import { UpdateGenerateImageTaskTemplateRequest } from '../../../imaging/models/generate-image-task-template.models';
import { ImagingService } from '../../../imaging/services/imagine.service';
import { BoardNode, ImageCreationNode } from '../../models/board-node.models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { instanceToPlain } from 'class-transformer';
import { TextToImageTemplate } from '../../../imaging/models/image-creation-template.models';
import { ControlGroupComponent } from '../../../../core/ui-kit/components/control-group/control-group.component';
import { NgScrollbarModule } from 'ngx-scrollbar';

type AspectRatio = "9:16" | "3:4" | "1:1" | "4:3" | "16:9";
type ImageSize = "small" | "medium" | "large";

type ImageDimension = {
	label: string;
	aspectRatio: AspectRatio;
	width: number;
	height: number;
	size: ImageSize;
}

@Component({
	selector: 'app-image-creation-node-settings',
	imports: [
		FormsModule,
		ReactiveFormsModule,
		CheckboxComponent,
		IconComponent,
		ButtonComponent,
		RangeSliderComponent,
		InputComponent,
		ControlGroupComponent,
		NgScrollbarModule
	],
	templateUrl: './image-creation-node-settings.component.html',
	styleUrl: './image-creation-node-settings.component.less',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageCreationNodeSettingsComponent implements OnChanges, OnInit {

	private readonly _changeDetector = inject(ChangeDetectorRef);
	private readonly _destroyRef = inject(DestroyRef);
	private readonly _imagingService = inject(ImagingService);

	@Input() node!: BoardNode;

	availableImageDimensions: ImageDimension[] = [];
	aspectRatio: AspectRatio = "1:1";
	imageSize: ImageSize = "medium";

	readonly formMidjourney = new FormGroup({
		__type: new FormControl<ImagingProviderEnum>(ImagingProviderEnum.Midjourney, [Validators.required]),
		isEnabled: new FormControl<boolean>(true, [Validators.required]),
		stylize: new FormControl<number>(100),
		weird: new FormControl<number>(0),
		chaos: new FormControl<number>(0),
		quality: new FormControl<string>("1"),
		aspectRatio: new FormControl<string>("1:1"),
		seed: new FormControl<number | null>(null),
		tile: new FormControl<boolean>(false),
	});

	readonly formSD = new FormGroup({
		__type: new FormControl<ImagingProviderEnum>(ImagingProviderEnum.SD, [Validators.required]),
		isEnabled: new FormControl<boolean>(true, [Validators.required]),
		numberOfImages: new FormControl<number>(1),
		width: new FormControl<number>(1024),
		height: new FormControl<number>(1024),
		numberOfInferenceSteps: new FormControl<number>(50),
		guidanceScale: new FormControl<number>(5),
	});

	constructor() {
		this.constructImageDimensions();
	}

	ngOnInit(): void {
		this.formMidjourney.valueChanges.pipe(
			takeUntilDestroyed(this._destroyRef),
			debounceTime(300),
			distinctUntilChanged()
		).subscribe((v) => {
			if (this.node instanceof ImageCreationNode && this.node.template instanceof TextToImageTemplate) {
				const request = UpdateGenerateImageTaskTemplateRequest.fromPlain(<UpdateGenerateImageTaskTemplateRequest>v);
				request.id = this.node.template.midjourneyTaskTemplate.id;
				request.prompt = this.node.template.midjourneyTaskTemplate.prompt;
				request.negativePrompt = this.node.template.midjourneyTaskTemplate.negativePrompt;
				this._imagingService.UpdateTaskTemplate(request).subscribe();
				Object.assign(this.node.template.midjourneyTaskTemplate, { ...v });
			}
		});

		this.formSD.valueChanges.pipe(
			takeUntilDestroyed(this._destroyRef),
			debounceTime(300),
			distinctUntilChanged()
		).subscribe((v) => {
			if (this.node instanceof ImageCreationNode && this.node.template instanceof TextToImageTemplate) {
				const request = UpdateGenerateImageTaskTemplateRequest.fromPlain(<UpdateGenerateImageTaskTemplateRequest>v);
				request.id = this.node.template.sdTaskTemplate.id;
				request.prompt = this.node.template.sdTaskTemplate.prompt;
				request.negativePrompt = this.node.template.sdTaskTemplate.negativePrompt;
				this._imagingService.UpdateTaskTemplate(request).subscribe();
				Object.assign(this.node.template.sdTaskTemplate, { ...v });
			}
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.node instanceof ImageCreationNode && this.node.template instanceof TextToImageTemplate) {
			const midjourneyPatchValue = instanceToPlain(this.node.template.midjourneyTaskTemplate);
			this.formMidjourney.patchValue(midjourneyPatchValue);
			this.formMidjourney.controls.__type.setValue(ImagingProviderEnum.Midjourney);

			const sdPatchValue = instanceToPlain(this.node.template.sdTaskTemplate);
			this.formSD.patchValue(sdPatchValue);
			this.formSD.controls.__type.setValue(ImagingProviderEnum.SD);

			const width = this.formSD.controls.width.value;
			const height = this.formSD.controls.height.value;
			if (height && width) {
				const dimension = this.findClosestDimensionBySize(width, height, this.availableImageDimensions);
				if (dimension) {
					this.imageSize = dimension.size;
					this.aspectRatio = dimension.aspectRatio;
				}
			}
		}
	}

	constructImageDimensions() {
		this.availableImageDimensions = [
			{ label: "512x512", width: 512, height: 512, aspectRatio: '1:1', size: 'small' },
			{ label: "1024x1024", width: 1024, height: 1024, aspectRatio: '1:1', size: 'medium' },
			{ label: "2048x2048", width: 2048, height: 2048, aspectRatio: '1:1', size: 'large' },
			// Small size
			{ label: "512x288", width: 512, height: 288, aspectRatio: '16:9', size: 'small' },
			{ label: "512x384", width: 512, height: 384, aspectRatio: '4:3', size: 'small' },
			{ label: "384x512", width: 384, height: 512, aspectRatio: '3:4', size: 'small' },
			{ label: "288x512", width: 288, height: 512, aspectRatio: '9:16', size: 'small' },
			// Medium size
			{ label: "1024x576", width: 1024, height: 576, aspectRatio: '16:9', size: 'medium' },
			{ label: "1024x768", width: 1024, height: 768, aspectRatio: '4:3', size: 'medium' },
			{ label: "768x1024", width: 768, height: 1024, aspectRatio: '3:4', size: 'medium' },
			{ label: "576x1024", width: 576, height: 1024, aspectRatio: '9:16', size: 'medium' },
			// Large size
			{ label: "2048x1152", width: 2048, height: 1152, aspectRatio: '16:9', size: 'large' },
			{ label: "2048x1536", width: 2048, height: 1536, aspectRatio: '4:3', size: 'large' },
			{ label: "1536x2048", width: 1536, height: 2048, aspectRatio: '3:4', size: 'large' },
			{ label: "1152x2048", width: 1152, height: 2048, aspectRatio: '9:16', size: 'large' }
		];
	}

	recalculateDimensions() {
		const dimension = this.availableImageDimensions.find(i => i.aspectRatio === this.aspectRatio && i.size === this.imageSize);

		if (!dimension) {
			return;
		}

		this.formSD.controls.width.setValue(dimension.width);
		this.formSD.controls.height.setValue(dimension.height);
	}

	findDimensionBySizeAndAspectRatio(imageSize: ImageSize, aspectRatio: AspectRatio): ImageDimension | undefined {
		return this.availableImageDimensions.find(i => i.aspectRatio === this.aspectRatio && i.size === imageSize);
	}

	findClosestDimensionByAspectRatio(width: number, height: number, dimensions: ImageDimension[]): ImageDimension {
		const inputRatio = width / height;

		let closest = dimensions[0];
		let minDelta = Math.abs(inputRatio - closest.width / closest.height);

		for (const dimension of dimensions) {
			const currentRatio = dimension.width / dimension.height;
			const delta = Math.abs(inputRatio - currentRatio);

			if (delta < minDelta) {
				closest = dimension;
				minDelta = delta;
			}
		}

		return closest;
	}

	findClosestDimensionBySize(width: number, height: number, dimensions: ImageDimension[]): ImageDimension {
		let closest = dimensions[0];
		let minDistance = this.calculateDistance(width, height, closest.width, closest.height);
		for (const dimension of dimensions) {
			const distance = this.calculateDistance(width, height, dimension.width, dimension.height);

			if (distance < minDistance) {
				closest = dimension;
				minDistance = distance;
			}
		}
		return closest;
	}

	private calculateDistance(width1: number, height1: number, width2: number, height2: number): number {
		return Math.sqrt((width1 - width2) ** 2 + (height1 - height2) ** 2);
	}
}


